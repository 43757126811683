import { NgModule } from '@angular/core';
import { HomeComponent } from './home.component';
import { SharedModule } from '../shared/shared.module';
import { FooterComponent } from './footer/footer.component';
import { FlapComponent } from './flap/flap.component';
import { NavComponent } from './nav/nav.component';
import { ConversationsComponent } from './conversations/conversations.component';
import { ConversationsItemComponent } from './conversations-item/conversations-item.component';

@NgModule({
  declarations: [
    HomeComponent,
    FooterComponent,
    FlapComponent,
    NavComponent,
    ConversationsComponent,
    ConversationsItemComponent
  ],
  imports: [
    SharedModule
  ],
  exports: [
    HomeComponent,
    FooterComponent,
    FlapComponent,
    NavComponent,
    ConversationsComponent
  ]
})
export class HomeModule { }

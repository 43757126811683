import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { User } from 'src/app/shared/interfaces/user';
import { Conversation } from 'src/app/shared/interfaces/conversation';

@Component({
  selector: 'app-conversations-item',
  templateUrl: './conversations-item.component.html',
  styleUrls: ['./conversations-item.component.scss']
})
export class ConversationsItemComponent implements OnInit {
  @Input() user: User;
  @Input() conversation: Conversation;
  @Output() chating = new EventEmitter<User>();

  imgLoaded = false;
  imgFailedToLoad = false;
  isMan: boolean;
  isWoman: boolean;

  constructor(
    private dialog: AppService
  ) { }

  ngOnInit() {
    this.isMan = this.conversation.interlocutor.gender === 1;
    this.isWoman = this.conversation.interlocutor.gender === 2;
  }

  checkOnlineStatus(dateOnline: Date, isNowOnline: boolean): boolean {
    if (isNowOnline) {
      return false;
    }
    dateOnline = new Date(dateOnline);
    const now = new Date();

    return Math.abs(dateOnline.getTime() - now.getTime()) / (24 * 60 * 60 * 1000) < 7;
  }
  goChat() {
    this.chating.emit(this.conversation.interlocutor);
  }
  imgError() {
    this.imgLoaded = false;
    this.imgFailedToLoad = true;
  }
  imgLoad() {
    this.imgFailedToLoad = false;
  }

  block() {
    this.dialog.throwDialog({
      display: true,
      type: 'remove',
      conversation: this.conversation,
      user: this.conversation.interlocutor
    });
  }
}

import { transition, style, animate, query, stagger, trigger } from '@angular/animations';

/** Poping element animation */
export const popAnimation = trigger('popAnimation', [
  transition(':enter', [
    query('.pop', [
      style({
        opacity: 0,
        transform: 'scale(1.1)'
      }),
      stagger(50, [
        animate('0.5s', style({
          opacity: 1,
          transform: 'scale(1)'
        }))
      ])
    ], { optional: true })
  ]),
]);

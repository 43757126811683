import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { UserService } from './user.service';
import { filter } from 'rxjs/operators';
import { Angulartics2 } from 'angulartics2';
import { User } from '../shared/interfaces/user';
import { AppInitializerService } from '../app-initializer.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnInit {
  @HostBinding('class.profil') display = false;
  @Input() me: User;
  isMan: boolean;
  displayProfil = false;
  displayWizzard = false;
  editProfilInProgress = false;
  hasQuiz = this.api.params.country === 'EG';

  get hasInfoPage(): boolean {
    return this.api.params.hasNews;
  }
  constructor(
    private user: UserService,
    private angulartics2: Angulartics2,
    private api: AppInitializerService
  ) {}

  ngOnInit() {
    this.user.logged().subscribe((login) => {
      this.me = login;
      if (login) {
        this.isMan = this.me.gender === 1;
        setTimeout(() => {
          this.user.shouldShowPopup.pipe(filter((show) => show === true)).subscribe(() => {
            this.displayWizzard = true;
            this.display = this.displayWizzard;
          });
        }, 5000);
      }
    });
  }
  editProfil() {
    this.displayProfil = !this.displayProfil;
    this.display = this.displayProfil;
    if (this.displayProfil) {
      this.angulartics2.eventTrack.next({
        action: 'clic_edit_my_profile',
        properties: {
          category: 'cat_my_profile',
        },
      });
    }
  }
  closeWizzard($event: boolean) {
    this.displayWizzard = !$event;
    this.display = this.displayWizzard;
  }
  showProfil($event: boolean) {
    this.displayWizzard = !$event;
    if (this.editProfilInProgress) {
    } else {
      this.displayProfil = $event;
    }
  }
  savingRequest(value: boolean) {
    this.editProfilInProgress = value;
  }
}

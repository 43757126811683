<div class="overlay" @fade (click)="close()" [class.success]="submitOk"></div>
<div
  [ngClass]="['background',popup.type, firstDisplay ? '' : confirmType === 'report' ? 'report' : 'confirm', submitOk ? 'success' : '']">
  <div class="background-ripple" @ripple></div>
  <div class="background-border background-element" @plop></div>
  <div class="background-plain background-element" @plop2></div>
</div>
<ng-container *ngIf="firstDisplay else confirm">
  <div class="block-content" @fade2 [ngSwitch]="popup.type">
    <ng-container *ngSwitchCase="'block'">
      <mat-icon svgIcon="lock"></mat-icon>
      <button class="button button-finger lighten" i18n="bloquer@@Bloc" (click)="showConfirm('block')">Bloquer</button>
      <button class="button button-finger lighten" i18n="bloquer et signaler@@BlockAndReport"
        (click)="showConfirm('report')">Bloquer
        &amp; signaler</button>
      <button class="button button-icon lighten">
        <mat-icon svgIcon="close" (click)="close()"></mat-icon>
      </button>
    </ng-container>
    <ng-container *ngSwitchCase="'remove'">
      <button class="button button-finger accent" i18n="supprimer@@Remove" (click)="removeConv()" angulartics2On="click"
        angularticsAction="clic_delete_message" angularticsCategory="cat_message">Supprimer</button>
      <button class="button button-finger lighten" i18n="bloquer@@Bloc" (click)="showConfirm('block')">Bloquer</button>
      <button class="button button-finger lighten" i18n="bloquer et signaler@@BlockAndReport"
        (click)="showConfirm('report')">Bloquer
        &amp; signaler</button>
      <button class="button button-icon lighten">
        <mat-icon svgIcon="close" (click)="close()"></mat-icon>
      </button>
    </ng-container>
    <ng-container *ngSwitchCase="'avatar'">
      <img [src]="popup.avatar.thumb" class="avatar">
      <p i18n="question supprimer avatar@@AskDeleteAvatar">Etes-vous sûr de vouloir supprimer cette photo?</p>
      <button class="button button-finger accent" i18n="supprimer@@Remove" (click)="removeAvatar()"
        angulartics2On="click" angularticsAction="clic_pop_up_confirm_delete_picture"
        angularticsCategory="cat_my_profile">Supprimer</button>
      <button class="button button-icon lighten">
        <mat-icon svgIcon="close" (click)="close()"></mat-icon>
      </button>
    </ng-container>
    <ng-container *ngSwitchCase="'delete'">
      <ng-container *ngIf="!deleteConfirm else unsubscribeDialog">
        <div class="center delete">

          <span i18n="Permiere partie du texte de désinscription avant le lien de désabonnement@@UnsubscribePopupText">
            ATTENTION : Si vous disposez d'un compte Premium, merci de vous désabonner avant de supprimer votre compte.
            Pour cela vous pouvez contacter nos équipes pour toute question. Téléphone : 09 70 34 03 48 (coût d'un appel
            local).
            E-mail: customer@weezchat.fr</span>&nbsp;
          <a href="{{unsubscriptionLink}}" target="_blank" *ngIf="unsubscriptionLink"
            i18n="Deuxieme partie du texte de désinscription le lien de désabonnement@@UnsubscribePopupTextLink">
            Ou cliquez ICI.
          </a>
        </div>
        <button class="button button-finger accent" (click)="deleteConfirm = true"
          i18n="Confirmer la suppression@@Confirm">CONFIRMER</button>
        <button class="button button-icon darken">
          <mat-icon svgIcon="close" (click)="close()"></mat-icon>
        </button>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
<ng-template #confirmBlock>
  <div class="block-content" @fade2>
    <button class="button button-finger accent" type="submit"
      i18n="Confirmer la suppression@@Confirm">CONFIRMER</button>
    <button class="button button-icon lighten">
      <mat-icon svgIcon="close" (click)="cancelConfirm()"></mat-icon>
    </button>
  </div>
</ng-template>
<ng-template #confirm>
  <div class="block-content" @fade2 [ngSwitch]="confirmType" *ngIf="!submitOk else valid">
    <ng-container *ngSwitchCase="'report'">
      <h3
        i18n="Question que l'on pose au user pour savoir si il veut vraiment bloquer et signaler un autre user @@AskBlockAndReportUser">
        Souhaitez-vous bloquer et signaler cette personne ?</h3>
      <mat-radio-group aria-labelledby="report-radio-group-label" class="report-radio-group" [(ngModel)]="reportReason">
        <mat-radio-button class="report-radio-button" [value]="'disturbe'"
          i18n="utilisateur bloqué car il dérange@@BlockReasonPersonDisturbMe">Me dérange</mat-radio-button>
        <mat-radio-button class="report-radio-button" [value]="'wrongId'"
          i18n="utilisateur bloqué car il n'a pas cette identitée@@BlockReasonFalseIdentity">A une fausse identité
        </mat-radio-button>
        <mat-radio-button class="report-radio-button" [value]="'rubberId'"
          i18n="utilisateur bloqué car il essaye de m'escroquer@@BlockReasonSwindle">Essaye de m'escroquer
        </mat-radio-button>
        <mat-radio-button class="report-radio-button" [value]="'rudeId'"
          i18n="utilisateur bloqué car il a des propos inappropriér@@BlockReasonInappropriateContent">Poste du contenu
          inapproprié</mat-radio-button>
      </mat-radio-group>
      <button class="button button-finger accent" type="submit" i18n="Confirmer la suppression@@Confirm"
        (click)="block()" angulartics2On="click" angularticsAction="clic_block_report_user" *ngIf="!submiting else submited "
        angularticsCategory="cat_message">CONFIRMER</button>
      <button class="button button-icon lighten">
        <mat-icon svgIcon="close" (click)="cancelConfirm()"></mat-icon>
      </button>
    </ng-container>
    <ng-container *ngSwitchCase="'block'">
      <h3
        i18n="question bloquer utilistateur|Question posé à l'utilisateur afin de vérifier son intention de bloquer un autre utilisateur@@AskBlockUser">
        Souhaitez-vous bloquer</h3>
      <div class="user-name">{{popup.user.nickname}}</div>
      <p>
        <span
          i18n="explication bloquer utilisateur|Informations relative au fait de bloquer un utilisateur@@InfoBlockUser">ne
          verra plus votre profil</span></p>
      <button class="button button-finger accent" type="submit" i18n="Confirmer la suppression@@Confirm"
        (click)="block()" *ngIf="!submiting else submited " angulartics2On="click"
        angularticsAction="clic_block_user" angularticsCategory="cat_message">CONFIRMER</button>
      <button class="button button-icon lighten">
        <mat-icon svgIcon="close" (click)="cancelConfirm()"></mat-icon>
      </button>
    </ng-container>
  </div>
</ng-template>
<ng-template #submited>
  <div class="tiny-loader" *ngIf="!datasSaved">
    <div class="tiny-loader-ripple">

      <div class="ripple ripple-left"></div>
      <div class="ripple ripple-right"></div>
    </div>
    <mat-icon svgIcon="close"></mat-icon>
  </div>
</ng-template>
<ng-template #valid>
  <div class="block-content success">
    <button class="button button-icon success">
      <mat-icon svgIcon="ok"></mat-icon>
    </button>
  </div>
</ng-template>

<ng-template #unsubscribeDialog>
  <div class="center delete"
    i18n="texte de la popup demandant le mot de passe afin de confirmer la suppression@@UnsubscribePopupConfirmText">
    Pour des raisons de sécurité, veuillez indiquer votre mot de passe pour confirmer la suppression de votre
    compte Weezchat
  </div>
  <form #form="ngForm" (ngSubmit)="onSubmit()">
    <label>
      <div class="input-holder">
        <mat-icon (click)="hiddenPassword = !hiddenPassword" [svgIcon]="hiddenPassword ? 'visible' : 'hide'">
        </mat-icon>
        <input [(ngModel)]="pwd" placeholder="Votre mot de passe" #password="ngModel" name="password"
          [type]="hiddenPassword ? 'password' : 'text'" class="focus"
          i18n-placeholder="label mot de passe|Label du champs mot de passe du profil@@PasswordLabel">
      </div>
    </label>
    <button class="button button-finger accent" type="submit"
      i18n="Confirmer la suppression@@Confirm" angulartics2On="click"
      angularticsAction="delete_my_account_confirm_popup" angularticsCategory="cat_my_profile">CONFIRMER</button>
  </form>
  <button class="button button-icon darken">
    <mat-icon svgIcon="close" (click)="close()"></mat-icon>
  </button>
</ng-template>
<ng-template #removed>
  <div class="block-content byebye">
    <h2 i18n="titre confirmant la suppression du compte@@removeAccountTitle">Votre compte a bien été supprimé</h2>
  </div>
</ng-template>

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WsService {
  // dating-notification-pp.dvetech.fr?token=TtD4dFtMCfu92eCK
  private api = environment.waitingAPI;
  private token = environment.waitingToken;
  private message$ = new Subject<any>();
  private ws = null;

  constructor() {}

  connect() {
    // console.log('ws connect', this.ws.readyState);
    this.ws = new WebSocket(`wss://${this.api}?token=${this.token}`);
    console.log('ws connect', this.ws.readyState);
  }
  disconnect() {
    this.ws.onclose = () => {};
    this.ws.close();
    this.ws = null;
  }
  check(id: number, country: string) {
    const self = this;
    console.log('websocket connecting ...', this.ws.readyState);
    if (this.ws.readyState === 1) {
      console.log('websocket connected');
      this.ws.send(
        JSON.stringify({
          action: 'userexist',
          operation_id: id,
          country,
        })
      );
      this.ws.onmessage = (event) => {
        this.message$.next(JSON.parse(event.data));
      };

      this.ws.onclose = (e) => {
        self.disconnect();
        setTimeout(self.connect, 1000);
      };

      this.ws.onerror = (err) => {
        console.error('Socket encountered error: ', err, 'Closing socket');
      };
    } else {
      if (this.ws.readyState !== 0) {
        this.disconnect();
        this.connect();
      }
      setTimeout(() => {
        this.check(id, country);
      }, 2000);
    }
  }

  get message() {
    return this.message$.asObservable();
  }

  set message(value) {
    this.message$.next(value);
  }
}

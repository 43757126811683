import { Pipe, PipeTransform } from '@angular/core';

/**
 * User age pipe
 *
 * Transform a birthdate into date
 * @see {@link https://developer.mozilla.org/fr/docs/Web/JavaScript/Reference/Objets_globaux/Date}
 */
@Pipe({
  name: 'age'
})
export class AgePipe implements PipeTransform {
  readonly now = new Date();

  readonly origin = new Date(0);

  transform(birthday: Date | string): number {
    if (typeof birthday === 'string') {
      birthday = new Date(birthday as string);
    }
    const ageDate = new Date(this.now.getTime() - birthday.getTime());
    return ageDate.getUTCFullYear() - this.origin.getUTCFullYear();
  }

  birthdateFromAge(age: number): string {
    const year = this.now.getUTCFullYear() - age;
    const month = this.now.getUTCMonth() + 1;
    const day = this.now.getUTCDate();
    return `${year}-${month > 9 ? month : `0${month}`}-${day > 9 ? day : `0${day}`}`;
  }
}

<form #form="ngForm" (ngSubmit)="onProfilFormSubmit(me)" fxFlex fxLayout="column" fxLayoutAlign="top center">
  <div class="label-holder">
    <label>
      <h3>
        <span class="label" i18n="label e-mail|Label du champs pseudo du profil@@PseudoLabel">Votre pseudo</span>
      </h3>
      <div class="input-holder">
        <mat-icon svgIcon="pencil" *ngIf="me.nickname !== null && !forms.nickname.focus"></mat-icon>
        <input [(ngModel)]="me.nickname" placeholder="Votre pseudo" #email="ngModel" name="nickname"
          i18n-placeholder="label e-mail|Label du champs pseudo du profil@@PseudoLabel"
          [class.empty]="!me.nickname || me.nickname.length === 0 || (me.nickname === 'chatboy' || me.nickname === 'chatgirl')"
          [class.focus]="forms.nickname.focus" (focus)="setFocusClass('nickname')" (blur)="setBlurClass('nickname')"
          (input)="dataListener('nickname', $event)">
      </div>
    </label>
    <label *ngIf="forms.email.available">
      <h3>
        <span class="label" i18n="label e-mail|Label du champs e-mail du profil@@EmailLabel">Votre email</span>
      </h3>
      <div class="input-holder">
        <mat-icon svgIcon="pencil" *ngIf="me.email !== null && !forms.email.focus"></mat-icon>
        <input [(ngModel)]="me.email" placeholder="Votre email" #email="ngModel" name="email"
          i18n-placeholder="label e-mail|Label du champs e-mail du profil@@EmailLabel" [class.empty]="!me.email"
          [class.focus]="forms.email.focus" (focus)="setFocusClass('email')" (blur)="setBlurClass('email')"
          (input)="dataListener('email', $event)">
      </div>
    </label>
    <label *ngIf="forms.phone.available">
      <h3>
        <span class="label" i18n="label e-mail|Label du champs e-mail du profil@@PhoneLabel">Votre numéro de
          téléphone</span>
      </h3>
      <div class="input-holder">
        <!-- <mat-icon svgIcon="pencil" *ngIf="me.phone !== null && !forms.phone.focus"></mat-icon> -->
        <input [(ngModel)]="me.phone" placeholder="Votre numéro de téléphone" #phone="ngModel" name="phone"
          i18n-placeholder="label e-mail|Label du champs e-mail du profil@@PhoneLabel" [class.empty]="!me.phone"
          [class.focus]="forms.phone.focus" (focus)="setFocusClass('phone')" (blur)="setBlurClass('phone')" disabled>
      </div>
    </label>
    <label>
      <h3>
        <span class="label" i18n="label mot de passe|Label du champs mot de passe du profil@@PasswordLabel">Votre mot de
          passe</span>
      </h3>
      <div class="input-holder">
        <mat-icon (click)="hiddenPassword = !hiddenPassword" [svgIcon]="hiddenPassword ? 'visible' : 'hide'"></mat-icon>
        <input [(ngModel)]="me.password" placeholder="Votre mot de passe" #email="ngModel" name="password"
          [type]="hiddenPassword ? 'password' : 'text'"
          i18n-placeholder="label mot de passe|Label du champs mot de passe du profil@@PasswordLabel"
          [class.empty]="!me.password" [class.focus]="forms.password.focus" (focus)="setFocusClass('password')"
          (blur)="setBlurClass('password')" (input)="dataListener('password', $event)">
      </div>
    </label>
  </div>
</form>

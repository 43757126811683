<div class="home">
  <section class="home-header">
    <div *ngIf="isAt" class="banner-at">
      Mit Bestätigung des Dienstes stimme ich ausdrücklich der sofortigen Leistungserbringung und
      dem Verlust des <a href="https://m.weezchat.at/rucktrittsrecht">Rücktrittsrecht</a> zu.
    </div>
    <div class="langue-change" *ngIf="linkedSite">
      <ng-container *ngFor="let site of linkedSite">
        <a [href]="site.url">
          <ng-container *ngIf="site.extCode === 'NL'">
            <img [src]="'assets/flags/lang-' + site.extCode + '.png'" />
            <span>Nederlandse versie</span>
          </ng-container>
          <ng-container *ngIf="site.extCode === 'EN IQ'">
            <span>English version</span>
          </ng-container>
          <ng-container *ngIf="site.extCode === 'IQ'">
            <span>النسخة العربية</span>
          </ng-container>
        </a>
      </ng-container>
    </div>
    <img src="assets/logo.png" alt="Logo" [ngClass.gt-md]="{ big: true }" />
    <div class="baseline">
      <p i18n="Baseline de Weezchat@@WeezchatTitle">Tchat &amp; Rencontres</p>
      <p i18n="Sous-baseline de Weezchat@@WeezchatSubtitle">100% direct en illimité</p>
    </div>
  </section>
  <section [ngClass]="{ 'home-slide-data welcome': true, active: slideActive === 'welcome' }">
    <div class="button-holder">
      <div class="button-container" (click)="changeSlide('members')">
        <button class="button button-icon lighten">
          <mat-icon svgIcon="woman"></mat-icon>
        </button>
        <span i18n="Titre de la page meetup@@ DiscoverMember">Découvrez nos membres</span>
      </div>
      <div class="button-container" (click)="changeSlide('apps')" *ngIf="apps">
        <button class="button button-icon lighten">
          <mat-icon svgIcon="app"></mat-icon>
        </button>
        <span i18n="Titre application home@@HomeTitleApplications">Nos applications</span>
      </div>
      <div class="button-container" (click)="changeSlide('concept')">
        <button class="button button-icon lighten">
          <mat-icon svgIcon="concept"></mat-icon>
        </button>
        <span i18n="Titre concept home@@TitleBloc1Page4HomePage">Notre concept</span>
      </div>
    </div>
  </section>
  <section [ngClass]="{ 'home-slide members': true, active: slideActive === 'members' }">
    <button class="button button-icon lighten closing-slide" (click)="changeSlide('welcome')">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
    <div class="home-slide-data">
      <h2 i18n="membre headline@@TitlePage2HomePage">
        Découvrez la plus grande communauté de Tchat en France.
      </h2>
      <p i18n="Sous titre de la page 2 de la Home Pagee@@SubtitlePage2HomePage">
        Des milliers de célibataires vous attendent !
      </p>
      <div class="button-holder row">
        <div class="button-container">
          <button class="button button-icon lighten" (click)="changeSlide('members')">
            <mat-icon svgIcon="woman"></mat-icon>
          </button>
        </div>
        <div class="button-container">
          <button class="button button-icon lighten" (click)="changeSlide('apps')">
            <mat-icon svgIcon="app"></mat-icon>
          </button>
        </div>
        <div class="button-container">
          <button class="button button-icon lighten" (click)="changeSlide('concept')">
            <mat-icon svgIcon="concept"></mat-icon>
          </button>
        </div>
      </div>
    </div>
    <ng-container *ngFor="let member of members">
      <div
        class="members-holder"
        [ngStyle]="{
          'background-image': 'url(' + member.avatar.thumb + ')',
          height: thumbSize + 'px',
          width: thumbSize + 'px'
        }"
      ></div>
    </ng-container>
  </section>
  <section
    class="home-slide apps"
    [ngClass]="{ 'home-slide apps': true, active: slideActive === 'apps' }"
    *ngIf="apps"
  >
    <button class="button button-icon lighten closing-slide" (click)="changeSlide('welcome')">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
    <div class="home-slide-data">
      <a
        class="android"
        angulartics2On="click"
        angularticsCategory="cat_home_page"
        angularticsAction="clic_google_store_button"
        [href]="playstore"
      >
        <svg
          version="1.1"
          id="Calque_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 200 100"
          style="enable-background: new 0 0 200 100"
          xml:space="preserve"
        >
          <g>
            <path
              class="st0"
              d="M87.9,33.8c-0.3,0-0.6,0.2-0.9,0.5c-0.2,0.3-0.4,0.7-0.4,1.2c0,0.5,0.1,0.9,0.4,1.2c0.3,0.3,0.6,0.5,0.9,0.5
		c0.3,0,0.6-0.2,0.9-0.5c0.2-0.3,0.4-0.7,0.4-1.2c0-0.5-0.1-0.9-0.4-1.2C88.6,34,88.2,33.8,87.9,33.8z"
            />
            <path
              class="st0"
              d="M94.7,33.8c-0.1,0-0.2,0-0.4,0c0,0.5,0,1,0,1.5c0,0.6,0,1.1,0,1.7c0.2,0,0.3,0,0.5,0c0.5,0,0.9-0.2,1.1-0.5
		c0.2-0.3,0.4-0.7,0.4-1.2c0-0.5-0.1-0.8-0.4-1.1C95.7,34,95.3,33.8,94.7,33.8z"
            />
            <path
              class="st0"
              d="M106.3,33.8c-0.2,0-0.3,0-0.5,0.1c0,0.4,0,1,0,1.5c0.1,0,0.1,0,0.2,0c0.9,0,1.3-0.3,1.3-0.8
		C107.4,34,107,33.8,106.3,33.8z"
            />
            <path
              class="st0"
              d="M101.8,33.8c0,0.1-0.2,0.8-0.6,2h1.3C102.1,34.5,101.9,33.9,101.8,33.8L101.8,33.8z"
            />
            <path
              class="st0"
              d="M68.3,33.8c0,0.1-0.2,0.8-0.6,2H69C68.6,34.5,68.4,33.9,68.3,33.8L68.3,33.8z"
            />
            <path
              class="st0"
              d="M82.8,33.8c-0.2,0-0.4,0-0.6,0c0,0.4,0,0.8,0,1.4c0.1,0,0.2,0,0.3,0c0.8,0,1.1-0.3,1.1-0.8
		C83.7,34,83.4,33.8,82.8,33.8z"
            />
            <path
              class="st0"
              d="M77.8,33.8c-0.1,0-0.2,0-0.4,0c0,0.5,0,1,0,1.5c0,0.6,0,1.1,0,1.7c0.2,0,0.3,0,0.5,0c0.5,0,0.9-0.2,1.1-0.5
		c0.2-0.3,0.4-0.7,0.4-1.2c0-0.5-0.1-0.8-0.4-1.1C78.7,34,78.3,33.8,77.8,33.8z"
            />
            <path
              class="st0"
              d="M117.2,33.8c-0.3,0-0.6,0.2-0.9,0.5c-0.2,0.3-0.4,0.7-0.4,1.2c0,0.5,0.1,0.9,0.4,1.2c0.3,0.3,0.6,0.5,0.9,0.5
		c0.3,0,0.6-0.2,0.9-0.5c0.2-0.3,0.4-0.7,0.4-1.2c0-0.5-0.1-0.9-0.4-1.2C117.9,34,117.6,33.8,117.2,33.8z"
            />
            <path
              class="st0"
              d="M110.6,33.8c-0.2,0-0.3,0-0.5,0.1c0,0.4,0,1,0,1.5c0.1,0,0.1,0,0.2,0c0.9,0,1.3-0.3,1.3-0.8
		C111.6,34,111.3,33.8,110.6,33.8z"
            />
            <path
              class="st0"
              d="M101.4,50.4c-1,0-1.9,0.4-2.6,1.1c-0.7,0.7-1.1,1.7-1.1,2.9c0,1.2,0.4,2.2,1.1,2.9c0.7,0.7,1.6,1.1,2.6,1.1
		c1,0,1.9-0.4,2.6-1.1c0.7-0.7,1.1-1.7,1.1-2.9c0-1.2-0.4-2.2-1.1-2.9C103.2,50.8,102.3,50.4,101.4,50.4z"
            />
            <path
              class="st0"
              d="M89.1,50.4c-1,0-1.9,0.4-2.6,1.1c-0.7,0.7-1.1,1.7-1.1,2.9c0,1.2,0.4,2.2,1.1,2.9c0.7,0.7,1.6,1.1,2.6,1.1
		c1,0,1.9-0.4,2.6-1.1c0.7-0.7,1.1-1.7,1.1-2.9c0-1.2-0.4-2.2-1.1-2.9C90.9,50.8,90.1,50.4,89.1,50.4z"
            />
            <path
              class="st0"
              d="M147.3,45.7h-3.5v5.7h3.5c0.9,0,1.6-0.3,2.1-0.9c0.5-0.6,0.8-1.3,0.8-2c0-0.7-0.3-1.4-0.8-2
		C148.9,46,148.2,45.7,147.3,45.7z"
            />
            <path
              class="st0"
              d="M160.1,55.2c-0.5,0.4-0.8,1-0.8,1.6c0,0.5,0.2,1,0.7,1.3c0.5,0.3,1,0.5,1.6,0.5c0.9,0,1.7-0.3,2.4-1
		c0.7-0.6,1.1-1.4,1.1-2.3c-0.6-0.5-1.5-0.7-2.7-0.7C161.4,54.5,160.6,54.7,160.1,55.2z"
            />
            <path
              class="st0"
              d="M129.9,50.4c-0.8,0-1.5,0.2-2,0.7c-0.6,0.5-1,1.1-1.2,2h6.5c0-0.6-0.3-1.3-0.9-1.8
		C131.8,50.7,131,50.4,129.9,50.4z"
            />
            <path
              class="st0"
              d="M113.5,50.4c-1,0-1.8,0.4-2.5,1.1c-0.7,0.7-1.1,1.7-1.1,2.9c0,1.2,0.4,2.2,1.1,2.9c0.7,0.7,1.5,1.1,2.5,1.1
		c1,0,1.8-0.4,2.5-1.1c0.7-0.7,1-1.7,1-3c0-1.2-0.3-2.2-1-3C115.4,50.8,114.5,50.4,113.5,50.4z"
            />
            <path
              class="st0"
              d="M176.3,22h-154c-4.1,0-7.5,3.3-7.5,7.5v40.3c0,4.1,3.3,7.5,7.5,7.5h154c4.1,0,7.5-3.3,7.5-7.5V29.5
		C183.7,25.4,180.4,22,176.3,22z M109.2,33.1h0.6c0.1,0,0.3,0,0.5,0c0.2,0,0.5,0,0.6,0c0.4,0,0.8,0.1,1.1,0.3
		c0.3,0.2,0.5,0.5,0.5,0.9c0,0.6-0.2,1-0.6,1.3c-0.4,0.3-0.9,0.4-1.5,0.4c-0.1,0-0.2,0-0.2,0c0,0.4,0,1,0,1.8h-1c0-0.7,0-1.5,0-2.4
		C109.2,34.5,109.2,33.8,109.2,33.1z M100.5,35.5c0.6-1.4,0.8-2.2,0.8-2.3h1.3c0,0.1,0.3,0.9,0.8,2.3c0.5,1.4,0.8,2.2,0.8,2.3H103
		c0-0.2-0.1-0.6-0.3-1.3H101c-0.2,0.7-0.3,1.2-0.3,1.3h-1.1C99.6,37.7,99.9,36.9,100.5,35.5z M93.4,33.1H94c0.1,0,0.3,0,0.5,0
		c0.2,0,0.3,0,0.4,0c0.9,0,1.5,0.2,1.9,0.6c0.4,0.4,0.6,0.9,0.6,1.5c0,0.7-0.2,1.3-0.6,1.8c-0.4,0.5-1,0.8-1.8,0.8
		c-0.4,0-0.8,0-1.4,0c0-0.7,0-1.5,0-2.4C93.5,34.5,93.4,33.7,93.4,33.1z M91.2,33.1h1c0,0.6,0,1.3,0,2.2c0,0.9,0,1.7,0,2.4h-1
		c0-0.7,0-1.5,0-2.4C91.2,34.5,91.2,33.7,91.2,33.1z M86.3,33.7c0.4-0.5,1-0.7,1.6-0.7c0.6,0,1.1,0.2,1.6,0.7c0.4,0.4,0.6,1,0.6,1.7
		c0,0.7-0.2,1.3-0.7,1.8c-0.4,0.5-1,0.7-1.6,0.7c-0.6,0-1.1-0.2-1.6-0.7c-0.4-0.4-0.6-1-0.6-1.7C85.6,34.8,85.9,34.2,86.3,33.7z
		 M76.4,33.1h0.6c0.1,0,0.3,0,0.5,0c0.2,0,0.3,0,0.4,0c0.9,0,1.5,0.2,1.9,0.6c0.4,0.4,0.6,0.9,0.6,1.5c0,0.7-0.2,1.3-0.6,1.8
		c-0.4,0.5-1,0.8-1.8,0.8c-0.4,0-0.8,0-1.4,0c0-0.7,0-1.5,0-2.4C76.5,34.5,76.5,33.7,76.4,33.1z M71.4,33.1h1.1
		c0,0.1,0.3,0.6,0.9,1.5c0.6,0.9,1,1.6,1.1,1.9h0.1c-0.1-0.5-0.1-1-0.1-1.4c0-0.6,0-1.3,0-2h0.9c0,0.6,0,1.3,0,2.2
		c0,0.9,0,1.7,0,2.4h-0.9c0-0.1-0.3-0.6-1-1.5c-0.7-1-1.1-1.6-1.2-2h-0.1c0.1,0.5,0.1,1,0.1,1.4c0,0.6,0,1.3,0,2.1h-0.9
		c0-0.7,0.1-1.5,0.1-2.4C71.4,34.5,71.4,33.7,71.4,33.1z M67,35.5c0.6-1.4,0.8-2.2,0.8-2.3h1.3c0,0.1,0.3,0.9,0.8,2.3
		c0.5,1.4,0.8,2.2,0.8,2.3h-1.1c0-0.2-0.1-0.6-0.3-1.3h-1.6c-0.2,0.7-0.3,1.2-0.3,1.3h-1.1C66.1,37.7,66.4,36.9,67,35.5z M29.5,33.8
		c7.2,4,14.4,8,21.5,12.1c0.3,0.2,0.3,0.3,0.1,0.5c-1.6,1.6-3.2,3.2-4.8,4.8c-0.2,0.2-0.3,0.3-0.5,0c-5.7-5.8-11.4-11.6-17.2-17.5
		c0,0-0.1-0.1-0.2-0.2C28.9,33.6,29.3,33.7,29.5,33.8z M27.3,69.3c-0.6-0.5-0.8-1.1-0.8-1.8c0-3.1,0-6.2,0-9.3c0-2.2,0-4.4,0-6.6
		c0,0,0,0,0,0c0-5.2,0-10.5,0-15.7c0-0.6,0.1-1.2,0.6-1.7c0.2-0.2,0.3-0.2,0.5,0c3.5,3.6,7.1,7.2,10.6,10.8c2.3,2.3,4.6,4.7,6.9,7
		c0.2,0.2,0.2,0.3,0,0.6c-5.6,5.6-11.2,11.2-16.8,16.8C27.9,69.6,27.7,69.6,27.3,69.3z M50.5,57.9c-6.7,3.7-13.4,7.3-20.1,11
		c-0.1,0.1-0.2,0.2-0.3,0.2c5.2-5.3,10.4-10.5,15.6-15.8c0.3-0.3,0.5-0.3,0.8,0c1.3,1.4,2.7,2.7,4,4.1
		C50.8,57.7,50.8,57.8,50.5,57.9z M59.2,53.1c-0.3,0.2-0.7,0.4-1,0.6c-2,1.1-4.1,2.2-6.1,3.4c-0.3,0.2-0.4,0.1-0.7-0.1
		c-1.4-1.5-2.9-3-4.3-4.4c-0.2-0.2-0.2-0.3,0-0.5c1.7-1.7,3.4-3.4,5.1-5c0.2-0.2,0.3-0.2,0.6-0.1c2,1.1,4,2.2,6,3.4
		c0.6,0.3,1.1,0.8,1.1,1.5C59.9,52.3,59.6,52.8,59.2,53.1z M80.4,58c-1.5,1.6-3.6,2.4-6,2.4c-2.4,0-4.4-0.8-6-2.5
		c-1.7-1.6-2.5-3.7-2.5-6.1s0.8-4.5,2.5-6.1c1.7-1.6,3.7-2.5,6-2.5c1.2,0,2.3,0.2,3.4,0.7c1.1,0.5,1.9,1.1,2.6,1.8l-1.4,1.4
		c-1.1-1.3-2.6-1.9-4.5-1.9c-1.7,0-3.2,0.6-4.5,1.8c-1.3,1.2-1.9,2.8-1.9,4.7c0,1.9,0.6,3.5,1.9,4.7c1.3,1.2,2.8,1.8,4.5,1.8
		c1.8,0,3.4-0.6,4.6-1.8c0.8-0.8,1.3-1.9,1.4-3.3h-6v-2h8c0.1,0.4,0.1,0.8,0.1,1.2C82.4,54.8,81.8,56.7,80.4,58z M82.3,35.9
		c0,0.6,0,1.2,0,1.8h-1c0-0.7,0-1.5,0-2.4c0-0.9,0-1.6,0-2.2H82c0.1,0,0.2,0,0.4,0c0.2,0,0.4,0,0.6,0c1.1,0,1.6,0.4,1.6,1.1
		c0,0.6-0.3,1-0.9,1.2v0.1c0.2,0.1,0.4,0.3,0.6,0.6c0.2,0.3,0.4,0.9,0.7,1.7h-1c-0.3-0.8-0.5-1.4-0.6-1.6c-0.1-0.2-0.4-0.3-0.7-0.3
		C82.6,35.9,82.4,35.9,82.3,35.9z M93.2,58.8c-1.1,1.1-2.5,1.7-4.1,1.7c-1.7,0-3.1-0.6-4.1-1.7c-1.1-1.1-1.6-2.6-1.6-4.3
		c0-1.7,0.5-3.1,1.6-4.3c1.1-1.1,2.5-1.7,4.1-1.7c1.7,0,3.1,0.6,4.1,1.7c1.1,1.1,1.6,2.6,1.6,4.3C94.9,56.2,94.3,57.6,93.2,58.8z
		 M105.5,58.8c-1.1,1.1-2.5,1.7-4.1,1.7c-1.7,0-3.1-0.6-4.1-1.7c-1.1-1.1-1.6-2.6-1.6-4.3c0-1.7,0.5-3.1,1.6-4.3
		c1.1-1.1,2.5-1.7,4.1-1.7c1.7,0,3.1,0.6,4.1,1.7c1.1,1.1,1.6,2.6,1.6,4.3C107.1,56.2,106.6,57.6,105.5,58.8z M106.1,36
		c-0.1,0-0.2,0-0.2,0c0,0.4,0,1,0,1.8h-1c0-0.7,0-1.5,0-2.4c0-0.9,0-1.6,0-2.2h0.6c0.1,0,0.3,0,0.5,0c0.2,0,0.5,0,0.6,0
		c0.4,0,0.8,0.1,1.1,0.3c0.3,0.2,0.5,0.5,0.5,0.9c0,0.6-0.2,1-0.6,1.3C107.2,35.9,106.7,36,106.1,36z M119.1,59.5
		c0,1.9-0.6,3.4-1.7,4.4c-1.1,1-2.5,1.5-4.1,1.5c-1.3,0-2.4-0.3-3.3-1c-0.8-0.7-1.4-1.4-1.8-2.3l1.9-0.8c0.2,0.6,0.6,1.1,1.2,1.5
		c0.6,0.4,1.2,0.6,2,0.6c1.2,0,2.1-0.3,2.7-1c0.7-0.7,1-1.6,1-2.8v-1.1H117c-0.4,0.5-0.9,1-1.6,1.4c-0.7,0.4-1.4,0.6-2.3,0.6
		c-1.5,0-2.7-0.6-3.8-1.7c-1.1-1.1-1.6-2.6-1.6-4.3c0-1.7,0.5-3.1,1.6-4.3c1.1-1.1,2.3-1.7,3.8-1.7c0.8,0,1.6,0.2,2.3,0.5
		c0.7,0.4,1.2,0.8,1.6,1.4h0.1v-1.6h2V59.5z M118.8,37.2c-0.4,0.5-1,0.7-1.6,0.7c-0.6,0-1.1-0.2-1.6-0.7c-0.4-0.4-0.6-1-0.6-1.7
		c0-0.7,0.2-1.3,0.7-1.8c0.4-0.5,1-0.7,1.6-0.7c0.6,0,1.1,0.2,1.6,0.7c0.4,0.4,0.6,1,0.6,1.7C119.5,36.1,119.3,36.7,118.8,37.2z
		 M123.2,60.1h-2.1V43.7h2.1V60.1z M124.3,37.8h-0.9c0-0.1-0.3-0.6-1-1.5c-0.7-1-1.1-1.6-1.2-2h-0.1c0.1,0.5,0.1,1,0.1,1.4
		c0,0.6,0,1.3,0,2.1h-0.9c0-0.7,0.1-1.5,0.1-2.4c0-0.9,0-1.6-0.1-2.2h1.1c0,0.1,0.3,0.6,0.9,1.5c0.6,0.9,1,1.6,1.1,1.9h0.1
		c-0.1-0.5-0.1-1-0.1-1.4c0-0.6,0-1.3,0-2h0.9c0,0.6,0,1.3,0,2.2C124.2,36.2,124.3,37,124.3,37.8z M135.4,54.9h-8.9
		c0.1,1.1,0.4,2,1.1,2.7c0.7,0.7,1.5,1,2.4,1c1.5,0,2.5-0.6,3.1-1.9l1.9,0.8c-0.4,0.9-1,1.6-1.8,2.2c-0.8,0.6-1.9,0.9-3.1,0.9
		c-1.7,0-3.1-0.6-4.1-1.7c-1.1-1.1-1.6-2.6-1.6-4.3c0-1.6,0.5-3,1.5-4.2c1-1.2,2.3-1.8,4-1.8c1.7,0,3,0.5,4,1.6
		c1,1.1,1.5,2.5,1.5,4.4L135.4,54.9z M150.9,52.1c-1,0.9-2.2,1.4-3.6,1.4h-3.5v6.6h-2.1V43.7h5.6c1.4,0,2.6,0.5,3.6,1.4
		c1,0.9,1.5,2.1,1.5,3.5C152.4,50,151.9,51.1,150.9,52.1z M155.9,60.1h-2.1V43.7h2.1V60.1z M167.1,60.1h-2v-1.6H165
		c-0.8,1.3-2.1,1.9-3.7,1.9c-1.2,0-2.2-0.3-3-1c-0.8-0.7-1.2-1.6-1.2-2.7c0-1.2,0.5-2.2,1.4-2.9c0.9-0.7,2.1-1,3.5-1
		c1.2,0,2.3,0.2,3,0.7v-0.3c0-0.8-0.3-1.5-0.8-2c-0.6-0.5-1.3-0.7-2.1-0.7c-0.6,0-1.2,0.1-1.7,0.4c-0.5,0.3-0.8,0.7-1,1.2l-1.9-0.8
		c0.3-0.7,0.8-1.3,1.5-1.9c0.8-0.6,1.8-0.9,3-0.9c1.4,0,2.6,0.4,3.6,1.3c0.9,0.8,1.4,2,1.4,3.6V60.1z M171.7,65h-2.2l2.6-5.7
		l-4.6-10.5h2.3l3.3,8.1h0l3.3-8.1h2.3L171.7,65z"
            />
          </g>
        </svg>
      </a>
      <!-- <div class=" ios" angulartics2On="click" angularticsCategory="cat_home_page"
        angularticsAction="clic_apple_store_button">
        <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
           viewBox="0 0 200 100" style="enable-background:new 0 0 200 100;" xml:space="preserve">
        <g>
          <path class="st0" d="M40.2,36h3c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4h-3c-0.2,0-0.4,0.2-0.4,0.4C39.9,35.8,40,36,40.2,36z
             M41.7,62.4c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6C43.3,63.1,42.6,62.4,41.7,62.4z M34,61.1h15.4
            V38.3H34V61.1z M83.3,53.6c-0.7,0-1.4,0.2-2,0.7c-0.6,0.5-1,1.1-1.2,1.9c-0.1,0.4-0.1,0.7-0.1,0.9v2.2c0,1,0.3,1.8,0.9,2.5
            c0.6,0.7,1.4,1,2.3,1c1.1,0,2-0.4,2.6-1.3c0.6-0.9,0.9-2,0.9-3.4c0-1.3-0.3-2.4-0.9-3.2C85.3,54,84.4,53.6,83.3,53.6z M137.3,53.4
            c-1.2,0-2.1,0.5-2.7,1.6c-0.5,0.9-0.8,1.9-0.8,3.2c0,1.2,0.3,2.3,0.8,3.1c0.6,1.1,1.5,1.6,2.7,1.6c1.1,0,2-0.5,2.6-1.6
            c0.6-0.9,0.8-1.9,0.8-3.2c0-1.2-0.3-2.3-0.8-3.1C139.3,53.9,138.4,53.4,137.3,53.4z M98,53.6c-0.7,0-1.4,0.2-2,0.7
            c-0.6,0.5-1,1.1-1.2,1.9c-0.1,0.4-0.1,0.7-0.1,0.9v2.2c0,1,0.3,1.8,0.9,2.5c0.6,0.7,1.4,1,2.3,1c1.1,0,2-0.4,2.6-1.3
            c0.6-0.9,0.9-2,0.9-3.4c0-1.3-0.3-2.4-0.9-3.2C99.9,54,99,53.6,98,53.6z M67.4,48.8L67.4,48.8c-0.2,0.8-0.5,1.9-0.9,3.5L65,57.1h5
            l-1.6-4.8C68.2,51.8,67.9,50.6,67.4,48.8z M159.7,53.3c-1,0-1.7,0.4-2.4,1.2c-0.5,0.6-0.8,1.4-0.9,2.3h6.2c0-0.9-0.2-1.6-0.6-2.3
            C161.5,53.7,160.7,53.3,159.7,53.3z M80.5,40.1c0-0.1,0-0.3,0-0.4v-0.9c-1.2,0-2.4,0.2-2.4,1.3c0,0.7,0.4,1,1,1
            C79.8,41.1,80.3,40.6,80.5,40.1z M64.3,35.8c-0.2-0.6-0.3-1.2-0.5-1.8h0c-0.1,0.5-0.3,1.2-0.5,1.8l-0.7,2.3h2.4L64.3,35.8z
             M171.7,22H26c-3.7,0-6.7,3-6.7,6.7v41.9c0,3.7,3,6.7,6.7,6.7h145.7c3.7,0,6.7-3,6.7-6.7V28.7C178.4,25,175.4,22,171.7,22z
             M162.4,35.1c2.2,0,3,1.8,3,3.3c0,0.3,0,0.6-0.1,0.7h-4.6c0,1.2,1,1.8,2.1,1.8c0.8,0,1.4-0.1,1.9-0.3l0.3,1.2
            c-0.6,0.3-1.4,0.4-2.4,0.4c-2.2,0-3.5-1.4-3.5-3.5C159.1,36.9,160.2,35.1,162.4,35.1z M135,35.3l0.1,1h0c0.3-0.5,1.1-1.2,2.2-1.2
            c1.2,0,2.4,0.8,2.4,2.9v4.1H138v-3.9c0-1-0.4-1.7-1.3-1.7c-0.7,0-1.2,0.5-1.4,1c-0.1,0.1-0.1,0.4-0.1,0.5v4h-1.7v-4.8
            c0-0.8,0-1.4-0.1-2H135z M116.8,35.1c2.2,0,3,1.8,3,3.3c0,0.3,0,0.6-0.1,0.7h-4.6c0,1.2,1,1.8,2.1,1.8c0.8,0,1.4-0.1,1.9-0.3
            l0.3,1.2c-0.6,0.3-1.4,0.4-2.4,0.4c-2.2,0-3.5-1.4-3.5-3.5C113.4,36.9,114.6,35.1,116.8,35.1z M109.7,32.2h1.7v10h-1.7V32.2z
             M96,36.4c-0.7,0-1.4,0.2-1.8,0.5l-0.3-1.1c0.5-0.3,1.4-0.6,2.5-0.6c2.2,0,2.8,1.4,2.8,2.9v2.5c0,0.6,0,1.2,0.1,1.6h-1.6l-0.1-0.8
            h0c-0.4,0.5-1.1,0.9-2,0.9c-1.4,0-2.2-1-2.2-2c0-1.7,1.5-2.6,4.1-2.6v-0.1C97.4,37.1,97.2,36.4,96,36.4z M85.7,32.4
            c0.6,0,1,0.4,1,0.9c0,0.5-0.4,0.9-1,0.9c-0.6,0-1-0.4-1-0.9C84.8,32.9,85.2,32.4,85.7,32.4z M86.6,35.3v6.9h-1.7v-6.9H86.6z
             M62.8,32.7H65l3.1,9.5h-1.9l-0.9-2.7h-3l-0.8,2.7h-1.8L62.8,32.7z M51.3,64.1c0,1.7-1.4,3-3,3h-13c-1.7,0-3-1.4-3-3V35.2
            c0-1.7,1.4-3,3-3h13c1.7,0,3,1.4,3,3V64.1z M72.2,64.8l-1.7-5.4h-6l-1.6,5.4h-3.1l5.9-18.4h3.7l6,18.4H72.2z M72.8,42.2h-1.7
            l-2.5-6.9h1.9l1.1,3.5c0.2,0.6,0.3,1.2,0.5,1.7h0c0.1-0.6,0.3-1.1,0.5-1.7l1.1-3.5h1.8L72.8,42.2z M76.4,40.3
            c0-1.7,1.5-2.6,4.1-2.6v-0.1c0-0.5-0.2-1.2-1.4-1.2c-0.7,0-1.4,0.2-1.8,0.5l-0.3-1.1c0.5-0.3,1.4-0.6,2.5-0.6
            c2.2,0,2.8,1.4,2.8,2.9v2.5c0,0.6,0,1.2,0.1,1.6h-1.6l-0.1-0.8h0c-0.4,0.5-1.1,0.9-2,0.9C77.2,42.3,76.4,41.3,76.4,40.3z M88,63.4
            c-1.1,1.2-2.5,1.7-4.1,1.7c-1.8,0-3-0.6-3.8-1.9v7h-3V55.9c0-1.4,0-2.9-0.1-4.4h2.6l0.2,2.1h0.1c1-1.6,2.5-2.4,4.5-2.4
            c1.6,0,2.9,0.6,3.9,1.9c1,1.2,1.6,2.9,1.6,4.9C89.8,60.3,89.2,62,88,63.4z M91,42.2h-1.7v-10H91V42.2z M102.6,63.4
            c-1.1,1.2-2.5,1.7-4.1,1.7c-1.8,0-3-0.6-3.8-1.9v7h-3V55.9c0-1.4,0-2.9-0.1-4.4h2.6l0.2,2.1h0.1c1-1.6,2.5-2.4,4.5-2.4
            c1.6,0,2.9,0.6,3.9,1.9c1.1,1.2,1.6,2.9,1.6,4.9C104.4,60.3,103.8,62,102.6,63.4z M104.6,42.3c-0.9,0-1.7-0.3-2.2-1.2h0l-0.1,1
            h-1.5c0-0.5,0.1-1.2,0.1-1.9v-8.1h1.7v4.1h0c0.4-0.7,1.2-1.1,2.2-1.1c1.7,0,2.9,1.4,2.9,3.5C107.7,41.1,106.2,42.3,104.6,42.3z
             M119.5,63.5c-1.2,1.1-2.9,1.6-5,1.6c-2,0-3.6-0.4-4.8-1.1l0.7-2.5c1.3,0.8,2.7,1.1,4.3,1.1c1.1,0,2-0.3,2.6-0.8
            c0.6-0.5,0.9-1.2,0.9-2c0-0.7-0.3-1.4-0.8-1.9c-0.5-0.5-1.4-1-2.5-1.4c-3.2-1.2-4.8-3-4.8-5.3c0-1.5,0.6-2.8,1.7-3.7
            c1.1-1,2.7-1.4,4.5-1.4c1.7,0,3.1,0.3,4.2,0.9l-0.7,2.4c-1-0.6-2.2-0.8-3.5-0.8c-1,0-1.9,0.3-2.4,0.8c-0.5,0.5-0.7,1-0.7,1.7
            c0,0.7,0.3,1.3,0.8,1.8c0.5,0.4,1.4,0.9,2.7,1.4c1.6,0.6,2.8,1.4,3.5,2.2c0.7,0.9,1.1,1.9,1.1,3.2
            C121.1,61.2,120.6,62.5,119.5,63.5z M129.9,53.7h-3.3v6.5c0,1.6,0.6,2.5,1.7,2.5c0.5,0,1,0,1.3-0.1l0.1,2.2
            c-0.6,0.2-1.3,0.3-2.3,0.3c-1.2,0-2.1-0.4-2.7-1.1c-0.7-0.7-1-1.9-1-3.6v-6.7h-1.9v-2.2h1.9v-2.4l2.9-0.9v3.3h3.3V53.7z
             M128.2,42.3c-1.9,0-3.4-1.3-3.4-3.5c0-2.3,1.5-3.6,3.5-3.6c2,0,3.4,1.4,3.4,3.5C131.8,41.2,130,42.3,128.2,42.3z M142,63.1
            c-1.2,1.3-2.9,2-4.9,2c-2,0-3.5-0.6-4.7-1.9c-1.2-1.3-1.7-2.9-1.7-4.9c0-2.1,0.6-3.8,1.8-5.1c1.2-1.3,2.8-2,4.8-2
            c2,0,3.5,0.6,4.7,1.9c1.1,1.3,1.7,2.9,1.7,4.9C143.8,60.1,143.2,61.8,142,63.1z M145.1,35.3h1V34l1.7-0.5v1.8h1.6v1.3h-1.6v3
            c0,0.8,0.2,1.3,0.9,1.3c0.3,0,0.5,0,0.7-0.1l0,1.3c-0.3,0.1-0.7,0.2-1.3,0.2c-0.6,0-1.2-0.2-1.5-0.6c-0.4-0.4-0.5-1-0.5-1.9v-3.2
            h-1V35.3z M152.7,54.1c-0.3-0.1-0.6-0.1-0.9-0.1c-1,0-1.8,0.4-2.4,1.2c-0.5,0.7-0.7,1.6-0.7,2.6v7h-3v-9.1c0-1.5,0-2.9-0.1-4.2h2.6
            l0.1,2.5h0.1c0.3-0.9,0.8-1.6,1.5-2.1c0.7-0.5,1.4-0.7,2.1-0.7c0.3,0,0.5,0,0.7,0V54.1z M153.1,38v4.1h-1.7v-10h1.7v4.1h0
            c0.2-0.3,0.5-0.6,0.9-0.8c0.4-0.2,0.8-0.3,1.2-0.3c1.2,0,2.4,0.8,2.4,3v4h-1.7v-3.9c0-1-0.4-1.7-1.3-1.7c-0.7,0-1.2,0.5-1.4,1
            C153.1,37.7,153.1,37.9,153.1,38z M165.3,58.9h-8.9c0,1.3,0.5,2.3,1.3,3c0.7,0.6,1.7,0.9,2.9,0.9c1.3,0,2.5-0.2,3.6-0.6l0.5,2.1
            c-1.3,0.6-2.7,0.8-4.5,0.8c-2.1,0-3.7-0.6-4.9-1.8c-1.2-1.2-1.8-2.8-1.8-4.9c0-2,0.6-3.7,1.6-5c1.2-1.4,2.7-2.1,4.7-2.1
            c1.9,0,3.4,0.7,4.4,2.1c0.8,1.1,1.2,2.5,1.2,4.2C165.4,58.1,165.4,58.5,165.3,58.9z M104.2,36.5c-0.7,0-1.4,0.5-1.5,1.3
            c0,0.1-0.1,0.3-0.1,0.5v1.1c0,0.1,0,0.3,0,0.4c0.2,0.7,0.8,1.2,1.5,1.2c1.1,0,1.7-0.9,1.7-2.3C106,37.5,105.4,36.5,104.2,36.5z
             M116.7,36.3c-1.1,0-1.5,1-1.6,1.7h3C118.1,37.4,117.8,36.3,116.7,36.3z M128.3,36.4c-1.2,0-1.7,1.2-1.7,2.3c0,1.3,0.7,2.3,1.7,2.3
            c1,0,1.7-1,1.7-2.3C130,37.6,129.5,36.4,128.3,36.4z M95,40.1c0,0.7,0.4,1,1,1c0.7,0,1.2-0.5,1.4-0.9c0-0.1,0-0.3,0-0.4v-0.9
            C96.2,38.8,95,39,95,40.1z M162.3,36.3c-1.1,0-1.5,1-1.6,1.7h3C163.8,37.4,163.5,36.3,162.3,36.3z"/>
        </g>
        </svg>
        </div> -->
      <div class="button-holder row">
        <div class="button-container">
          <button class="button button-icon lighten" (click)="changeSlide('members')">
            <mat-icon svgIcon="woman"></mat-icon>
          </button>
        </div>
        <div class="button-container">
          <button class="button button-icon lighten" (click)="changeSlide('apps')">
            <mat-icon svgIcon="app"></mat-icon>
          </button>
        </div>
        <div class="button-container">
          <button class="button button-icon lighten" (click)="changeSlide('concept')">
            <mat-icon svgIcon="concept"></mat-icon>
          </button>
        </div>
      </div>
    </div>
  </section>
  <section
    class="home-slide concept"
    [class.active]="slideActive === 'concept'"
    [class.mobile]="!desktop"
  >
    <button class="button button-icon lighten closing-slide" (click)="changeSlide('welcome')">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
    <div class="concept-holder accent">
      <h2 i18n="membre headline@@TitleBloc1Page4HomePage">
        Découvrez la plus grande communauté de Tchat en France.
      </h2>
      <span i18n="page 3 notre concept@@Bloc1Page4HomePage">
        Tchat en ligne, rencontres et qualité, le mix parfait qui vous permet de découvrir les
        célibataires près de chez vous. Pour des rencontres amicales et amoureuses en toute sécurité
      </span>
      <!-- <h3>n’hésitez plus et rejoignez la communauté Weezchat.</h3> -->
      <mat-icon svgIcon="messages"></mat-icon>
    </div>
    <div class="concept-holder primary">
      <h2 i18n="membre headline@@TitleBloc2Page4HomePage">
        Découvrez la plus grande communauté de Tchat en France.
      </h2>
      <span i18n="page 3 notre présence@@Bloc2Page4HomePage"
        >Weezchat est un service international qui est disponible dans + de 10 pays à travers le
        monde. + de 1 500 nouveaux inscrits et des milliers de messages échangés tous les jours !.
      </span>
      <mat-icon svgIcon="countries"></mat-icon>
    </div>
    <div class="concept-holder dark">
      <h2 i18n="membre headline@@TitleBloc3Page4HomePage">
        Découvrez la plus grande communauté de Tchat en France.
      </h2>
      <p i18n="page 3 nos offres@@Bloc3Page4HomePage">
        Vous souhaitez découvrir Weezchat et profiter du tchat en ILLIMITÉ ?! Bénéficiez de l'offre
        (abonnement 3€ par semaine) ICI.
      </p>
      <mat-icon svgIcon="woman"></mat-icon>
    </div>
  </section>
</div>

<div class="input-focus">
  <div class="input-overlay" (click)="blurInput()"></div>
  <div class="input-choices" *ngIf="call">
    <div class="input-choices-header">
      <div class="number" *ngIf="cities">{{ cities.length }}</div>
    </div>
    <div class="input-preset" *ngFor="let city of cities" (click)="setCity(city);">
      {{city.name}}
      <small *ngIf="city.zip">{{city.zip}}</small>
      <small *ngIf="city.short_name">({{city.short_name}})</small>
    </div>
  </div>
  <div class="input-holder">
    <input  type="text" [(ngModel)]="newVal" (keydown.enter)="blurInput()" #inputFocus />
    <div class="button button-icon primary" (click)="blurInput()">
      <mat-icon svgIcon="ok"></mat-icon>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { Conversation } from '../shared/interfaces/conversation';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  unread = 0;
  notifications = [];
  notifying = false;
  timer = null;

  constructor(
    private app: AppService
  ) { }

  ngOnInit() {
    this.app.notifications().subscribe( res => {
      this.showNotification(res);
    });
  }
  showNotification(conv: Conversation ) {
    this.notifying = true;
    this.unread++;
    this.notifications.map(int => {
      int.showActions = false;
      int.answering = false;
    });
    const user = this.notifications.find(i => {
      i.conversation.lastMessage.body = conv.lastMessage.body;
      i.unread++;
      i.showActions = true;
      return i.conversation.interlocutor.id === conv.interlocutor.id;
    });
    if (!user) {
      const index = this.notifications.length;
      this.notifications.push({ conversation: conv, showActions: true, unread: 1, answering: false, index });
    }
    this.timer = setTimeout(() => {
      this.notifying = false;
    }, 8000);
  }

  closeNotification() {
    this.notifying = false;
    clearTimeout(this.timer);
  }

  removeNotification(notif) {
    this.notifications = this.notifications.filter(n => notif.message.id !== n.message.id);
    if (this.notifications.length === 0) {
      this.closeNotification();
    }
  }
}

<div class="flap-holder">
  <button class="cord button button-round medium accent" *ngIf="!desktop && animationState === 'mobileLoggedClosed'"
    (click)="toggleFlapContent();">
    <mat-icon svgIcon="messages"></mat-icon>
  </button>
  <div class="flap-header" [ngStyle]="{
        'width': desktop ? headerWidth + 'px' : '100%'
      }">
    <button class="cord button button-round accent"
      *ngIf="!desktop && (animationState === 'mobileLoginOpened' || animationState === 'mobileLoggedOpened')"
      (click)="toggleFlapContent();">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
    <svg version="1.1" class="flap-header-background" xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 320 48"
      style="enable-background:new 0 0 320 48;" xml:space="preserve" *ngIf="!desktop">
      <path d="M259,18C198.9,30.9,125.7,36,63,29C26.1,24.9,4.1,19.5-2,17.6V50h324V0h-2.1C314,2.7,293.1,10.7,259,18z" />
    </svg>
    <svg version="1.1" class="flap-header-foreground" xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 320 48"
      style="enable-background:new 0 0 320 48;" xml:space="preserve" *ngIf="!desktop">
      <path class="white"
        d="M257,24c-46,13-94,21-151,21c-46.5,0-95.8-5.2-108-6.6V50h324V0h-1.6C315.3,3.1,295,13.3,257,24z" />
    </svg>
    <svg version="1.1" class="flap-header-background" xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 50 320"
      style="enable-background:new 0 0 50 320;" xml:space="preserve" *ngIf="desktop">
      <path
        d="M26.32,61c9.55,60.1,13.32,133.3,8.14,196c-3.03,36.9-7.03,58.9-8.44,65H50V-2H13v2.1C15,6,20.92,26.9,26.32,61z" />
    </svg>
    <svg version="1.1" class="flap-header-foreground" xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 50 320"
      style="enable-background:new 0 0 50 320;" xml:space="preserve" *ngIf="desktop">
      <path class="st0"
        d="M30.76,63c9.62,46,15.54,94,15.54,151c0,46.5-3.85,95.8-4.88,108H50V-2H13v1.6C15.29,4.7,22.84,25,30.76,63z" />
    </svg>
  </div>
  <div [ngClass]="{ 'flap-content': true, 'logged' : logged }" [@flapAnimation]="animationState">
    <button class="button button-finger accent" *ngIf="!desktop && !opened && !logged" (click)="toggleFlapContent();"
      i18n="@@SignIn">Connexion</button>
    <div [ngClass]="{ 'router-holder' : true, 'display' : opened}">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

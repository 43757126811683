import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { City } from './shared/interfaces/city';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  private error = new Subject<any>();
  private dialog = new Subject<any>();
  private footer = new Subject<boolean>();
  private notif = new Subject<any>();
  private inputSubject = new Subject<any>();
  private inputValueSubject = new Subject<string>();

  displayError(): Observable<any> {
    return this.error.asObservable();
  }
  throwError(err) {
    this.error.next( err );
  }

  displayDialog(): Observable<any> {
    return this.dialog.asObservable();
  }
  throwDialog(dial: any) {
    this.dialog.next( dial );
  }
  showInput(value: string | City, call: boolean) {
    this.inputSubject.next({show: true, value, call});
  }
  hideInput(value: string | City) {
    this.inputSubject.next({show: false, value});
  }
  change(val: string) {
    this.inputValueSubject.next(val);
  }
  get state(): Observable<any> {
    return this.inputSubject.asObservable();
  }
  get value(): Observable<string> {
    return this.inputValueSubject.asObservable();
  }
  throwFooter(value: boolean) {
    this.footer.next(value);
  }
  showHideFooter(): Observable<boolean> {
    return this.footer.asObservable();
  }
  showNotif(notifs: any) {
    this.notif.next(notifs);
  }
  notifications(): Observable<any> {
    return this.notif.asObservable();
  }
}

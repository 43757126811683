import { Component, OnInit } from '@angular/core';
import { UserSearchService } from '../services/user-search.service';
import { User } from '../shared/interfaces/user';
import { AppInitializerService } from '../app-initializer.service';
import { trackPageView } from '@snowplow/browser-tracker';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  slideActive = 'welcome';
  members: User[];
  thumbSize: number;
  apps: boolean;
  playstore: string;
  desktop = false;
  linkedSite = null;
  URL;
  isAt = null;

  constructor(private users: UserSearchService, private api: AppInitializerService) {
    this.apps = !!api.params.androidLink || !!api.params.iOSLink;
    this.playstore = api.params.androidLink ? api.params.androidLink.href : null;
    this.isAt = this.api.params.extCode === 'AT';
    this.URL = api.params.landingPageLink.href || null;
    // trackPageView();
  }

  ngOnInit() {
    if (this.api.params.linkedSites.length > 0) {
      this.linkedSite = this.api.params.linkedSites;
    }
    this.desktop = window.innerWidth > 960;
    const w = window.innerWidth / Math.floor(window.innerWidth / 100);
    const h = window.innerHeight / Math.floor(window.innerHeight / 160);
    this.thumbSize = window.innerWidth < 960 ? w : h;

    this.users.presentationList().subscribe((res) => {
      this.members = res;
    });
  }
  changeSlide(name: string) {
    this.slideActive = name;
  }
  goToStore() {
    console.log('store');
  }
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserService } from '../../user.service';
import { AppService } from 'src/app/app.service';
import { Subscription } from 'rxjs';
import { User } from 'src/app/shared/interfaces/user';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-account-auth',
  templateUrl: './account-auth.component.html',
  styleUrls: ['./account-auth.component.scss']
})
export class AccountAuthComponent implements OnInit {
  @Input() me: User;
  @Output() saveChanges = new EventEmitter<any[]>();

  submitted = false;
  hiddenPassword = true;
  forms = {
    nickname: { available: true, focus: false, previous: '' },
    email: { available: null, focus: false, previous: '' },
    phone: { available: null, focus: false, previous: '' },
    password: { available: true, focus: false, previous: '' }
  };
  valueSubscription: Subscription;
  constructor(private users: UserService, private app: AppService) {}

  ngOnInit() {
    this.forms.email.available =
      environment.completeProfileInputs.includes('email') || this.me.email;
    this.forms.phone.available =
      environment.completeProfileInputs.includes('phoneNumber') || this.me.phone;
    this.forms.nickname.previous = this.me.nickname;
    this.forms.email.previous = this.me.email;
    this.forms.phone.previous = this.me.phone;
    this.forms.password.previous = this.me.nickname;
  }
  onProfilFormSubmit(account): void {
    this.submitted = true;
    this.users.update(account).subscribe(() => {
      this.submitted = false;
    });
  }
  dataListener(name: string, event) {
    const textarea = event.target as HTMLTextAreaElement;
    const val = textarea.value;
    if (this.forms[name].previous !== val) {
      this.saveChanges.emit([name, val]);
    }
  }
  setBlurClass(el: string) {
    this.forms[el].focus = false;
  }
  setFocusClass(el: string) {
    if (window.innerWidth <= 960) {
      this.app.showInput(this.me[el], false);
      this.valueSubscription = this.app.state.subscribe(res => {
        if (this.forms[el].previous !== res.value) {
          this.me[el] = res.value;
          this.saveChanges.emit([el, res.value]);
        }
        this.valueSubscription.unsubscribe();
      });
    } else {
      this.forms[el].focus = true;
    }
  }
}

<div
  class="main-background"
  [class.logged]="logged"
  [class.desktop]="desktop"
  [class.rtl]="rtl"
  [class.offline]="!online"
></div>
<main
  [ngClass]="[desktop ? 'desktop' : 'mobile', rtl ? 'rtl' : 'ltr']"
  [class.offline]="!online"
  [dir]="rtl ? 'rtl' : 'ltr'"
>
  <div class="app" *ngIf="logged && hasApp && appBannerShown && appBannerShown < 4">
    <mat-icon svgIcon="close" (click)="appBannerShown = 4"></mat-icon>
    <div class="app-icon">
      <img src="assets/logo-app-50x50.png" />
    </div>
    <div class="app-text">
      <h2 i18n="@@appBannerTitle">title</h2>
      <p i18n="@@appBannerDesc">desc</p>
    </div>
    <a [href]="hasApp" target="_blank" i18n="@@appBannerButtonDownload">DOWNLOAD</a>
  </div>
  <div class="main-content" [class.logged]="logged">
    <app-account *ngIf="logged"></app-account>
    <app-conversations *ngIf="logged; else home"></app-conversations>
  </div>
  <app-flap [desktop]="desktop" [logged]="logged"></app-flap>
</main>
<div class="footer-mexican" *ngIf="footerBanner && footerBanner.length">
  <div class="footer-mexican-link">
    <a [href]="footerBanner[0].value">{{ footerBanner[0].name }} </a>
    <a [href]="footerBanner[1].value">{{ footerBanner[1].name }} </a>
    <a [href]="footerBanner[2].value">{{ footerBanner[2].name }} </a>
    <a [href]="footerBanner[3].value">{{ footerBanner[3].name }} </a>
  </div>
  <p>
    Atención a clientes:
    <a href="mailto:sac@digitalvirgoamericas.com">sac@digitalvirgoamericas.com</a> Tel.
    <a href="tel:8002889979">800 288 9979</a> de 9am-6pm Lun-Vie
  </p>
  <!-- <p *ngIf="operatorMx === 'telcel'; else att">
    Att. Cliente de lunes a viernes de 9:00 a 18:00 al <a href="tel:8002889979">800 288 9979</a>
  </p>
  <ng-template #att>
    <p>
      Atención a clientes:
      <a href="mailto:sac@digitalvirgoamericas.com">sac@digitalvirgoamericas.com</a> Tel.
      <a href="tel:8002889979">800 288 9979</a> de 9am-6pm Lun-Vie
    </p>
  </ng-template> -->
</div>
<ng-template #home>
  <ng-container *ngIf="online; else offline">
    <app-home></app-home>
  </ng-container>
</ng-template>
<app-notifications></app-notifications>
<app-error
  *ngIf="error.display"
  [message]="error.message"
  [code]="error.code"
  [error]="error.error"
></app-error>
<app-block *ngIf="popup.display" [popup]="popup"></app-block>
<app-focus-input
  *ngIf="showInputFocus.show"
  [val]="showInputFocus.value"
  [call]="showInputFocus.call"
>
</app-focus-input>
<footer>
  <button
    class="legal button button-round lighten right"
    (click)="toggleLegal()"
    *ngIf="!legalDisplay && footerDisplay"
  >
    <mat-icon svgIcon="information"></mat-icon>
  </button>
  <button
    class="legal button button-icon lighten right"
    (click)="toggleLegal()"
    *ngIf="legalDisplay"
  >
    <mat-icon svgIcon="close"></mat-icon>
  </button>
  <app-footer
    (hideLegal)="toggleLegal()"
    [displayBanner]="displayBanner"
    *ngIf="legalDisplay"
  ></app-footer>
</footer>
<ng-template #offline>
  <div class="banner-offline" *ngIf="!online">
    <mat-icon svgIcon="nointernet"></mat-icon>
    <ng-container i18n="@@Internetconnexionrequired"
      >Vous n'êtes pas connecté à un réseau ...</ng-container
    >
  </div>
</ng-template>

import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { pluck } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Site } from './site';

export const SITE_QUERY = gql`
  query site($id: ID!) {
    site(where: { id: $id }) {
      id
      extCode
      key
      avatarSize
      title
      footerLinks {
        link {
          id
          href
          content
          title
          target
          useAjax
        }
      }
      landingPageLink {
        id
        href
        content
        title
        target
      }
      freeSubscription
      showAllCities
      rtl
      hasNews
      cookieLink {
        id
        href
        content
        title
        target
      }
      unsubscribeLink {
        id
        href
        content
        title
        target
      }
      socialMediaLinks {
        id
        socialMedia
        link {
          id
          href
          content
          title
          target
        }
      }
      iOSLink {
        id
        href
        content
        title
        target
      }
      androidLink {
        id
        href
        content
        title
        target
      }
      linkedSites {
        url
        extCode
      }
    }
  }
`;
@Injectable({
  providedIn: 'root',
})
export class AppInitializerService {
  params = null;

  constructor(private apollo: Apollo) {}

  Init(): Promise<Site> {
    return this.apollo
      .query<any>({
        query: SITE_QUERY,
        variables: {
          id: environment.id,
        },
      })
      .pipe(pluck('data', 'site'))
      .toPromise()
      .then((res) => {
        const ret = { ...res };
        ret.country = res.extCode === 'BENL' ? 'BE' : res.extCode === 'CDP' ? 'FR' : res.extCode;
        ret.country = res.extCode === 'EN IQ' ? 'IQ' : res.extCode;
        this.params = ret;
        return ret;
      });
  }
}

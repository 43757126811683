<div class="container" routerLinkActive="selected" fxLayout="row" fxLayoutAlign="start center">
  <a fxFlex fxLayout="row" fxLayoutAlign="start center" class="infos">
    <span fxFlex [ngClass]="{status: true,
        'logged': conversation.interlocutor.logged,
        'unread': conversation.unreadCount,
        'online-last-days': checkOnlineStatus(conversation.interlocutor.date_online, conversation.interlocutor.logged)
      }">
      <span class="read-count" *ngIf="conversation.unreadCount > 0">{{conversation.unreadCount}}</span>
    </span>
    <div [ngClass]="['avatar', isMan ? 'man' : 'woman']" *ngIf="conversation.interlocutor.avatar.id === null else imgloaded"
    routerLink="/chat/user/{{conversation.interlocutor.id}}/profil">
      <mat-icon svgIcon="man" *ngIf="isMan"></mat-icon>
      <mat-icon svgIcon="woman" *ngIf="isWoman"></mat-icon>
    </div>
    <div fxFlex class="user" angulartics2On="click" angularticsAction="clic_read_message"
      angularticsCategory="cat_message" routerLink="/chat/user/{{conversation.interlocutor.id}}/conversation" (click)="goChat()">
      <div class="nickname">{{conversation.interlocutor.nickname}}</div>
      <div class="last-message">
        <span class="you"
        *ngIf="conversation.interlocutor.id !== conversation.lastMessage.sender"
        i18n="ecris vous si dernier message ecrit par l'utilisateur connecte@@You">Vous</span>
        <ng-container *ngIf="conversation.lastMessage.type === 'text' else messageImage">
            &laquo; {{conversation.lastMessage.body|slice:0:40}} &raquo;
            <ng-container *ngIf="conversation.lastMessage.body.length > 40">...</ng-container>
        </ng-container>
        <ng-template #messageImage>
          <span i18n="quand le dernier message est une photo@@lastMessageImage">IMAGE</span>
        </ng-template>
      </div>
    </div>
  </a>
  <button class="wrapper-close button button-icon accent" (click)="block()">
    <mat-icon svgIcon="close"></mat-icon>
  </button>
</div>

<ng-template #imgloaded>
  <img class="avatar" [src]="conversation.interlocutor.avatar.thumb" [alt]="conversation.interlocutor.nickname"
    angulartics2On="click" angularticsAction="checking_profil" angularticsCategory="cat_meet" routerLink="/chat/user/{{conversation.interlocutor.id}}/profil">
</ng-template>

import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root'
})
export class AuthResolverService implements Resolve<any> {

  constructor(
    private user: UserService,
    private token: TokenService
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<any>|Promise<any>|any {
    const hasToken = !!this.token.get();
    if (hasToken) {
      return this.user.find();
    } else {
      return false;
    }
  }
}

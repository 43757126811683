import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { SignUpType } from 'src/app/shared/enums/sign-up-type.enum';
import { UserService } from 'src/app/account/user.service';
import { ActivatedRoute } from '@angular/router';
import { isNumber } from 'util';
import { AppService } from 'src/app/app.service';
import { AppInitializerService } from 'src/app/app-initializer.service';
import { trackPageView } from '@snowplow/browser-tracker';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss'],
})
export class ForgetPasswordComponent implements OnInit {
  /** Sign-up methods enum */
  SignUpType;

  /** The site sign-up type */
  email: string;
  submitted = false;
  success = false;

  constructor(
    private user: UserService,
    private route: ActivatedRoute,
    private dialog: AppService,
    private users: UserService,
    private api: AppInitializerService
  ) {
    this.SignUpType =
      environment.completeProfileInputs.length > 1 ? 'both' : environment.completeProfileInputs[0];
    if (this.api.params.country === 'MX' || this.api.params.country === 'AR') {
      this.SignUpType = 'email';
    }
    // trackPageView();
  }

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      if (params.get('login')) {
        this.email = params.get('login');
        this.users.showSubscribing(true);
      }
    });
  }

  onSubmit() {
    if (this.email && this.email !== '') {
      let readyToSend = true;
      if (this.api.params.extCode === 'AR' || this.api.params.extCode === 'MX') {
        readyToSend = isNaN(Number(this.email));
      }
      if (readyToSend) {
        this.submitted = true;
        this.user.sendPasswordRecovery(this.email).subscribe((res) => {
          this.submitted = false;
          this.success = res;
        });
      } else {
        this.dialog.throwError({
          message: 'error',
          code: 302,
          error: 'EmailRequired',
        });
      }
    }
  }
}

<div class="flap-notification" [class.display]="notifying">
    <div class="notification-header">
      <button class="button button-icon accent">
        <mat-icon svgIcon="messages"></mat-icon>
      </button>
      <div class="notification-title" i18n="notification nouveau message@@notifNewMessage">Nouveau message</div>
      <button class="button button-icon darken" (click)="closeNotification();">
        <mat-icon svgIcon="close"></mat-icon>
      </button>
    </div>
    <ng-container *ngFor="let notif of notifications">
      <div class="sender-infos" (click)="closeNotification();"
       routerLink="/chat/user/{{notif.conversation.interlocutor.id}}/conversation">
        <div class="sender-avatar">
          <div [ngClass]="['avatar', notif.conversation.interlocutor.gender === 1 ? 'man' : 'woman']"
            *ngIf="notif.conversation.interlocutor.avatar.id === null else imgloaded">
            <mat-icon [svgIcon]="notif.conversation.interlocutor.gender === 1 ? 'man' : 'woman'"></mat-icon>
          </div>
        </div>
        <div class="sender-message">
          <div class="sender-name" [class.man]="notif.conversation.interlocutor.gender === 1"
            [class.woman]="notif.conversation.interlocutor.gender === 2">
            {{notif.conversation.interlocutor.nickname}}<span i18n="notification dit@@notifSay"> dit</span>
          </div>
          <div class="sender-text">
            {{notif.conversation.lastMessage.body | slice:0:32}}</div>
        </div>
        <div class="sender-actions" *ngIf="notif.showActions">
        </div>
      </div>
      <ng-template #imgloaded>
        <img class="avatar" src="{{notif.conversation.interlocutor.avatar.thumb}}">
      </ng-template>
    </ng-container>
  </div>
  

import { Component, OnInit, Input } from '@angular/core';
import { User } from 'src/app/shared/interfaces/user';
import { AppInitializerService } from 'src/app/app-initializer.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  @Input() me: User;
  isMan: boolean;

  flapState: any;

  get hasInfoPage(): boolean {
    return this.api.params.hasNews;
  }
  constructor(private api: AppInitializerService) {}

  ngOnInit() {
    this.isMan = this.me.gender === 1;
  }
}

<div class="hobbies">
  <div
    class="hobby pop"
    [class.selected]="hobby.selected"
    [ngClass]="['hobby-' + hobby.id]"
    (click)="selectUnselectHobby(hobby.id)"
    *ngFor="let hobby of me.hobbies"
  >
    <!-- <mat-icon svgIcon="HOBBY_{{hobby.id}}"></mat-icon> -->
    #
    <ng-container [ngSwitch]="hobby.id">
      <ng-container *ngSwitchCase="1" i18n="sport@@HOBBY_SPORT">sport</ng-container>
      <ng-container *ngSwitchCase="3" i18n="@@HOBBY_TRAVEL">Voyages</ng-container>
      <ng-container *ngSwitchCase="5" i18n="@@HOBBY_COOKING">hhh</ng-container>
      <ng-container *ngSwitchCase="7" i18n="@@HOBBY_MUSIC">hhh</ng-container>
      <ng-container *ngSwitchCase="9" i18n="@@HOBBY_CINEMA">hhh</ng-container>
      <ng-container *ngSwitchCase="11" i18n="@@HOBBY_NATURE">hhh</ng-container>
      <ng-container *ngSwitchCase="13" i18n="@@HOBBY_PARTY">hhh</ng-container>
      <ng-container *ngSwitchCase="15" i18n="@@HOBBY_PAINTING">hhh</ng-container>
      <ng-container *ngSwitchCase="17" i18n="@@HOBBY_GAMING">hhh</ng-container>
      <ng-container *ngSwitchCase="19" i18n="@@HOBBY_TV">hhh</ng-container>
      <ng-container *ngSwitchCase="21" i18n="@@HOBBY_BOOKS">hhh</ng-container>
      <ng-container *ngSwitchCase="23" i18n="@@HOBBY_HITEK">hhh</ng-container>
      <ng-container *ngSwitchCase="25" i18n="@@HOBBY_PHOTO">hhh</ng-container>
      <ng-container *ngSwitchCase="27" i18n="@@HOBBY_SHOPPING">hhh</ng-container>
      <ng-container *ngSwitchCase="29" i18n="@@HOBBY_THEATRE">hhh</ng-container>
    </ng-container>
    <!-- <span>{{hobby.name}}</span> -->
  </div>
</div>

<div class="crop" fxLayout="column">
  <!-- <ngx-loading [show]="!cropperReady"></ngx-loading> -->

  <button class="button button-round lighten close" angulartics2On="click"
    angularticsAction="clic_cancel_download_picture" angularticsCategory="cat_my_profile" (click)="noCrop()">
    <mat-icon svgIcon="close"></mat-icon>
  </button>

  <div class="crop-holder">
    <image-cropper fxFlex [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="1"
      [resizeToWidth]="avatarSize" format="jpeg" (imageCroppedBase64)="imageCroppedBase64($event)"
      (imageLoaded)="imageLoaded()" (loadImageFailed)="imageLoadFailed()"
      [style.display]="cropperReady ? null : 'none'"></image-cropper>
  </div>

  <button class="button button-finger primary save" (click)="cropping()" i18n="enregistrer@@Save">Enregistrer</button>
</div>

<ng-container *ngIf="loading">
  <div class="loader">
    <div class="loader-overlay"></div>
    <div class="loader-ripple"></div>
    <div class="loader-content">
      <mat-icon svgIcon="photo"></mat-icon>
    </div>
  </div>
</ng-container>

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { TokenService } from '../../token.service';
import { Avatar } from 'src/app/shared/interfaces/avatar';
import { ApiResponse } from 'src/app/shared/interfaces/api-response';
import { AppInitializerService } from 'src/app/app-initializer.service';

/**
 * User avatar service
 *
 * This service allow the user to change, delete an add avatars
 * It also provide a subject to watch when the current user change his/her avatar
 */
@Injectable({
  providedIn: 'root'
})
export class AvatarService {
  /** Event fired when the current user change his/her avatar */
  readonly onMainAvatarChange = new Subject<Avatar>();
  readonly onAddAvatar = new Subject<FileEvent>();
  readonly onAvatarDelete = new Subject<Avatar>();
  readonly onAvatarUpload = new Subject<Avatar>();

  constructor(
    private http: HttpClient,
    private token: TokenService,
    private api: AppInitializerService
  ) {}
  setAddAvatarSubject(value) {
    this.onAddAvatar.next(value);
  }
  cropAvatar(): Observable<any> {
    return this.onAddAvatar;
  }
  getNewAvatar(): Observable<Avatar> {
    return this.onAvatarUpload;
  }

  uploadAvatar(base64Image: string): Observable<Avatar> {
    const body = new FormData();
    body.append('key', this.api.params.key.toString());
    body.append('userauth', this.token.get());
    body.append('image', base64Image);
    return this.http
      .post<ApiResponse<{ avatar: Avatar }>>(`${environment.api}/upload_image`, body)
      .pipe(
        map(res => {
          this.onAvatarUpload.next(res.data.avatar);
          return res.data.avatar;
        })
      );
  }

  deleteAvatar(avatar: Avatar): Observable<void> {
    const params = new HttpParams()
      .set('key', this.api.params.key.toString())
      .set('country', this.api.params.country)
      .set('userauth', this.token.get())
      .set('avatarId', avatar.id.toString());
    return this.http
      .delete<ApiResponse<void>>(`${environment.api}/user_delete_avatar`, { params })
      .pipe(
        map(res => {
          this.onAvatarDelete.next(avatar);
          return;
        })
      );
  }

  defineMainAvatar(avatar: Avatar): Observable<void> {
    const body = {
      key: this.api.params.key.toString(),
      country: this.api.params.country,
      userauth: this.token.get(),
      avatarId: avatar.id
    };
    return this.http.put<ApiResponse<void>>(`${environment.api}/user_select_avatar`, body).pipe(
      map(res => {
        this.onMainAvatarChange.next(avatar);
        return;
      })
    );
  }
}

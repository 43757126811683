import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewChecked } from '@angular/core';
import { City } from '../interfaces/city';
import { CitySearchService } from '../city-input/city-search.service';
import { AppService } from 'src/app/app.service';
import { AppInitializerService } from 'src/app/app-initializer.service';

@Component({
  selector: 'app-focus-input',
  templateUrl: './focus-input.component.html',
  styleUrls: ['./focus-input.component.scss'],
})
export class FocusInputComponent implements OnInit, AfterViewChecked {
  @Input() val: string | City;
  @Input() call: boolean;
  @ViewChild('inputFocus') input: ElementRef;

  cities: City[];
  inputValue: string;
  hasAutoComplete;
  citiesAutoComplete: City[];

  set newVal(value: string) {
    this.inputValue = value;
    this.app.change(this.inputValue);
    if (this.call) {
      if (this.hasAutoComplete) {
        this.searchCities.byName(250).subscribe((cities) => (this.cities = cities));
      } else {
        this.cities = this.citiesAutoComplete.filter(
          (city) =>
            value.toLocaleUpperCase() === city.name.slice(0, value.length).toLocaleUpperCase()
        );
        // this.searchCities.byName(10, value as string).subscribe((cities) => (this.cities = cities));
      }
    }
  }
  get newVal(): string {
    return this.inputValue;
  }

  constructor(
    private app: AppService,
    private searchCities: CitySearchService,
    private api: AppInitializerService
  ) {
    this.hasAutoComplete = api.params.showAllCities;
  }

  ngOnInit() {
    this.inputValue = this.val as string;
    if (this.call) {
      if (this.hasAutoComplete) {
        this.searchCities.byName(250).subscribe((cities) => {
          this.cities = cities;
          this.citiesAutoComplete = cities;
        });
      } else {
        this.searchCities
          .byName(10, this.val as string)
          .subscribe((cities) => (this.cities = cities));
      }
    }
  }
  ngAfterViewChecked(): void {
    this.input.nativeElement.focus();
  }
  blurInput() {
    this.app.hideInput(this.newVal);
  }
  setCity(city: City) {
    this.app.hideInput(city);
  }
}

import { Component, OnInit, Input, HostBinding, EventEmitter, Output } from '@angular/core';
import { UserService } from '../../user.service';
import { popAnimation } from 'src/app/shared/animations/pop-animation';
import { User } from 'src/app/shared/interfaces/user';

@Component({
  selector: 'app-account-profil-hobbies',
  templateUrl: './account-profil-hobbies.component.html',
  styleUrls: ['./account-profil-hobbies.component.scss'],
  animations: [
    popAnimation
  ]
})
export class AccountProfilHobbiesComponent implements OnInit {
  @HostBinding('@popAnimation')
  @Input () me: User;
  @Output () hobbiesSelected = new EventEmitter<number>();
  @Output () saveChanges = new EventEmitter<any[]>();

  savingAvailable = false;
  hobbiesSelectedOrigin: number;

  constructor(
    private users: UserService,
    ) { }

  ngOnInit() {

  }
  selectUnselectHobby(id): void {
    const hobbies = this.getHobbiesSelected();
    const hobby = this.me.hobbies.find(h => {
      return h.id === id;
    });
    if (hobbies.num < 9 || hobby.selected) {
      hobby.selected = !hobby.selected;
      const newVal = hobby.selected ? hobbies.num + 1 : hobbies.num - 1;
      this.saveChanges.emit(['hobbies', this.me.hobbies]);
      this.hobbiesSelected.emit(newVal);
    }
  }
  getHobbiesSelected() {
    const hobbies = [];
    this.me.hobbies.filter(h => {
      if (h.selected) {
        hobbies.push(h.id);
      }
    });
    return { hobbies, num: hobbies.length };
  }
}

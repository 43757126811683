<label [class.focus]="focus">
  <h3>
    <span class="label" i18n="Votre ville@@CityLabel">Votre ville</span>
  </h3>
  <div class="input-choices" [class.display]="focus">
    <!-- <div class="number" *ngIf="cities">{{ cities.length }}</div> -->
    <div class="input-preset" *ngFor="let city of cities" (click)="setCity(city);">
      {{city.name}}
      <small *ngIf="city.zip">{{city.zip}}</small>
      <small *ngIf="city.short_name">({{city.short_name}})</small>
    </div>
  </div>
  <div class="input-holder">
    <mat-icon svgIcon="pencil" *ngIf="newVal !== null && !focus"></mat-icon>
    <input type="text" [(ngModel)]="newVal" (click)="switchFocus(newVal)" name="city"
      [class.focus]="focus">
    <mat-icon svgIcon="close" *ngIf="newVal !== null" (click)="resetInput()"></mat-icon>
  </div>
</label>

import {
  BrowserModule,
  HammerModule,
  HAMMER_GESTURE_CONFIG,
  Meta,
} from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppInterceptor } from './app-interceptor';
import { Angulartics2Module } from 'angulartics2';
import { AccountModule } from './account/account.module';
import { SharedModule } from './shared/shared.module';
import { HomeModule } from './home/home.module';
import { NotificationsComponent } from './notifications/notifications.component';
import { AppInitializerService } from './app-initializer.service';
import { GraphQLModule } from './graphql.module';
import { Site } from './site';
import { LoggedGuard } from './logged.guard';
import { MyHammerConfig } from './my-hammer-config';

export function initializeApp(AIS: AppInitializerService) {
  return (): Promise<Site> => {
    return AIS.Init();
  };
}
@NgModule({
  declarations: [AppComponent, NotificationsComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: !environment.production }),
    Angulartics2Module.forRoot(),
    GraphQLModule.forRoot(),
    AccountModule,
    HomeModule,
    HammerModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppInitializerService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptor,
      multi: true,
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
    LoggedGuard,
    Meta,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

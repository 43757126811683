<h2>
  <mat-icon svgIcon="messages"></mat-icon><span i18n="titre chat|Titre du chat au dessus des conversations@@ChatTitle"> Vos conversations</span>
</h2>
<ul>
  <ng-container *ngIf="hasConversation else addConversation"></ng-container>
  <li *ngFor="let conversation of conversationsList" class="pop">
    <app-conversations-item [user]="me" [conversation]="conversation" (chating)="resetUnreadCount($event)">
    </app-conversations-item>
  </li>
  <ng-template #addConversation>
  </ng-template>
</ul>


import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Angulartics2 } from 'angulartics2';
import { AppService } from 'src/app/app.service';
import { User } from 'src/app/shared/interfaces/user';
import { UserService } from '../user.service';
import { AvatarService } from './account-profil-avatars/avatar.service';
import { AppInitializerService } from 'src/app/app-initializer.service';

@Component({
  selector: 'app-account-profil',
  templateUrl: './account-profil.component.html',
  styleUrls: ['./account-profil.component.scss'],
})
export class AccountProfilComponent implements OnInit {
  // data$ = this.route.parent.parent.data;
  @Input() me: User;
  @Output() savingRequest = new EventEmitter<boolean>();

  slideActive = 'avatars';
  hideElement = false;
  hobbiesSelectedValue = 0;
  avatarsNumber = 0;
  enable = false;
  submitted = false;
  datasSaved = false;
  sendHobbiesTracker = false;
  sendNicknameTracker = false;
  sendDescriptionTracker = false;
  warning = false;
  accessError = false;
  informationError = false;
  linkedSite = null;

  newImage: FileEvent;
  crop = false;

  meInput = null;
  meTmp = {
    nickname: null,
    email: null,
    phone: null,
    password: null,
    description: null,
    birthdate: null,
    city: null,
    hobbies: null,
  };

  hobbiesLabel = [
    'sport',
    'travel',
    'cooking',
    'music',
    'cinema',
    'nature',
    'party',
    'painting',
    'games',
    'tv',
    'reading',
    'hitek',
    'photo',
    'shopping',
    'theater',
  ];

  constructor(
    private user: UserService,
    private router: Router,
    private dialog: AppService,
    private angulartics2: Angulartics2,
    private avatars: AvatarService,
    private api: AppInitializerService
  ) {}

  ngOnInit() {
    if (this.api.params.linkedSites.length > 0) {
      console.log('linked site', this.api.params.linkedSites);
      this.linkedSite = this.api.params.linkedSites;
    }
    this.avatarsNumber = this.me.avatars[0].id === null ? 0 : this.me.avatars.length;
    this.me.hobbies.filter((h) => {
      if (h.selected) {
        this.hobbiesSelectedValue++;
      }
    });
    this.avatars.cropAvatar().subscribe((img) => {
      if (img) {
        this.newImage = img;
        this.crop = true;
      } else {
        this.crop = false;
      }
    });
    this.setMeInput();
  }
  changeSlide(name: string) {
    this.slideActive = name;
  }
  logout() {
    this.user.logout();
    this.router.navigate(['/connection']);
  }
  deleteAccount() {
    this.dialog.throwDialog({
      display: true,
      type: 'delete',
      user: this.me,
    });
  }
  setHobbiesSelectedValue(value) {
    this.hobbiesSelectedValue = value;
  }
  showHideSaveButton($event) {
    this.sendHobbiesTracker = $event[0] === 'hobbies';
    this.sendNicknameTracker = $event[0] === 'nickname';
    this.sendDescriptionTracker = $event[0] === 'description';
    this.meTmp[$event[0]] = $event[1];
    this.enable = true;
    this.warning = false;
    this.accessError = false;
    this.informationError = false;

    const key = Object.keys(this.meTmp);
    key.map((val) => {
      if (!this.meTmp[val] && this.meTmp[val] === '' && val !== 'description') {
        this.enable = false;
        this.warning = true;
        switch (val) {
          case 'nickname':
          case 'email':
          case 'phone':
          case 'password':
            this.accessError = true;
            break;
          case 'city':
            this.informationError = true;
            break;
        }
      }
    });
    if (this.enable) {
      const changes = key.filter((k) => this.meTmp[k] !== null);
      this.enable = changes.length > 0;
    }
    console.log(this.me);
  }
  saveProfil() {
    this.submitted = true;
    this.enable = false;
    const key = Object.keys(this.meTmp);
    key.map((val) => {
      if (this.meTmp[val]) {
        this.me[val] = this.meTmp[val];
      }
    });
    this.user.update(this.me).subscribe((res) => {
      this.submitted = false;
      this.datasSaved = true;
      setTimeout(() => (this.datasSaved = false), 2800);
      if (this.sendHobbiesTracker) {
        this.sendHobbiesTracker = false;
        let selectedHobbies = 0;
        this.setMeInput();
        this.me.hobbies.map((h) => {
          if (h.selected) {
            selectedHobbies++;
            this.angulartics2.eventTrack.next({
              action: 'cat_my_profile',
              properties: {
                category: 'select_hobbies_name',
                label: this.hobbiesLabel[(Number(h.id) - 1) / 2],
              },
            });
          }
        });
        this.angulartics2.eventTrack.next({
          action: 'cat_my_profile',
          properties: {
            category: 'select_hobbies',
            label: selectedHobbies,
          },
        });
      }
      if (this.sendDescriptionTracker) {
        this.angulartics2.eventTrack.next({
          action: 'filling_presentation_box',
          properties: {
            category: 'cat_my_profile',
          },
        });
      }
      if (this.sendNicknameTracker) {
        this.sendNicknameTracker = false;
        this.angulartics2.eventTrack.next({
          action: 'cat_my_profile',
          properties: {
            category: 'change_pseudo_validate',
          },
        });
      }
    });
  }
  setMeInput() {
    this.meInput = {
      nickname: this.me.nickname,
      email: this.me.email,
      phone: this.me.phone,
      password: this.me.password,
      description: this.me.description,
      birthdate: this.me.birthdate,
      city: this.me.city,
      hobbies: this.me.hobbies,
    };
  }
  /**
   * On photo selection
   *
   * Load the photos inside the browser and show cropper with it
   * @param $event Input change event with file
   */
  closeCrop($event) {
    this.crop = $event;
  }
  cropImage($img: string) {
    this.avatars.uploadAvatar($img).subscribe((avatar) => {
      this.crop = false;
      // profile cropped photo tracker
      this.angulartics2.eventTrack.next({
        action: 'clic_save_picture',
        properties: {
          category: 'cat_my_profile',
        },
      });
    });
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { transition, style, animate, state, trigger } from '@angular/animations';
import { Conversation } from 'src/app/shared/interfaces/conversation';
import { UserService } from 'src/app/account/user.service';
import { ConversationsService } from 'src/app/chat/conversations.service';
import { finalize } from 'rxjs/operators';
import { AppInitializerService } from 'src/app/app-initializer.service';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-flap',
  templateUrl: './flap.component.html',
  styleUrls: ['./flap.component.scss'],
  animations: [
    trigger('flapAnimation', [
      state(
        'mobileLoginClosed',
        style({
          height: '58px',
        })
      ),
      state(
        'mobileLoginOpened',
        style({
          height: '100%',
        })
      ),
      state(
        'mobileLoggedClosed',
        style({
          height: '10px',
        })
      ),
      state(
        'mobileLoggedOpened',
        style({
          height: '95%',
        })
      ),
      state(
        'start',
        style({
          width: '0px',
        })
      ),
      state(
        'login',
        style({
          width: '480px',
        })
      ),
      state(
        'logged',
        style({
          width: '66%',
        })
      ),
      transition('* => *', animate('.25s .25ms ease-out')),
    ]),
  ],
})
export class FlapComponent implements OnInit /*, OnChanges*/ {
  @Input() desktop: boolean;
  @Input() logged: boolean;
  animationState: string;
  opened = false;

  conversationList: Conversation[] = [];
  isLoading = false;

  headerWidth: number;

  asFooterLinks = null;

  constructor(
    private user: UserService,
    private conversations: ConversationsService,
    private api: AppInitializerService,
    private dialog: AppService
  ) {
    this.asFooterLinks = api.params.footerLinks.length > 0;
    this.desktop = window.innerWidth > 960;
    this.headerWidth = window.innerHeight * (50 / 320);
  }

  ngOnInit() {
    this.animationState = this.setAnimation(this.opened);
    this.user.subscribing().subscribe((value) => {
      if (value && !this.desktop) {
        this.animationState = 'mobileLoginOpened';
        this.opened = value;
      }
    });
    this.user.logged().subscribe((login) => {
      // login.operator = 33409;
      this.logged = !!login;
      if (!!login) {
        // login.operator = 33420;
        this.conversations
          .conversationList(100, 0)
          .pipe(finalize(() => (this.isLoading = false)))
          .subscribe((conversations) => {
            this.opened = !(conversations && conversations.length > 0);
            this.animationState = this.setAnimation(this.opened);
            this.user.setConversations(conversations);
          });
      } else {
        this.opened = !!login;
        this.animationState = this.setAnimation(this.opened);
      }
    });
  }

  setAnimation(switcher: boolean) {
    if (this.desktop) {
      return this.logged ? 'logged' : 'login';
    }
    if (this.logged) {
      return switcher ? 'mobileLoggedOpened' : 'mobileLoggedClosed';
    }
    return switcher ? 'mobileLoginOpened' : 'mobileLoginClosed';
  }
  toggleFlapContent() {
    this.opened = !this.opened;
    this.animationState = this.setAnimation(this.opened);
  }
}

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AppInitializerService } from 'src/app/app-initializer.service';

@Component({
  selector: 'app-account-profil-avatar-crop',
  templateUrl: './account-profil-avatar-crop.component.html',
  styleUrls: ['./account-profil-avatar-crop.component.scss']
})
export class AccountProfilAvatarCropComponent {
  @Input() imageChangedEvent: FileEvent;
  @Output() closing = new EventEmitter<boolean>();
  @Output() crop = new EventEmitter<string>();
  croppedImage: string;
  cropperReady = false;
  loading = true;
  avatarSize: number;

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCroppedBase64(image: string) {
    this.croppedImage = image;
  }
  imageLoaded() {
    this.cropperReady = true;
    this.loading = false;
  }
  imageLoadFailed() {
    console.log('Load failed');
  }
  noCrop() {
    this.closing.emit(false);
  }
  cropping() {
    this.loading = true;
    this.crop.emit(this.croppedImage);
  }
  constructor(private api: AppInitializerService) {
    this.avatarSize = api.params.avatarSize;
  }
}

import { NgModule } from '@angular/core';
import { AccountComponent } from './account.component';
import { AccountProfilWizzardComponent } from './account-profil/account-profil-wizzard/account-profil-wizzard.component';
import { AccountProfilComponent } from './account-profil/account-profil.component';
import { AccountProfilInfosComponent } from './account-profil/account-profil-infos/account-profil-infos.component';
import { AccountProfilAvatarsComponent } from './account-profil/account-profil-avatars/account-profil-avatars.component';
import { AccountProfilHobbiesComponent } from './account-profil/account-profil-hobbies/account-profil-hobbies.component';
import { AccountAuthComponent } from './account-profil/account-auth/account-auth.component';
import { AccountProfilAvatarCropComponent
} from './account-profil/account-profil-avatars/account-profil-avatar-crop/account-profil-avatar-crop.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    AccountComponent,
    AccountProfilWizzardComponent,
    AccountProfilComponent,
    AccountProfilInfosComponent,
    AccountProfilAvatarsComponent,
    AccountProfilHobbiesComponent,
    AccountAuthComponent,
    AccountProfilAvatarCropComponent,
  ],
  imports: [
    SharedModule
  ],
  exports: [
    AccountComponent
  ]
})
export class AccountModule { }

<form #form="ngForm" fxFlex fxLayout="column" fxLayoutAlign="top center"
[class.disabled]="submitted">
  <div class="label-holder">
    <label>
      <h3>
        <span class="label" i18n="age@@DescriptionProfileDefault">Votre description</span>
      </h3>
      <div class="input-holder">
        <mat-icon svgIcon="pencil" *ngIf="me.description === null || (me.description.length !== 0 && !forms.description.focus)"></mat-icon>
        <textarea [(ngModel)]="me.description" placeholder="Votre description" #description="ngModel" name="description"
          i18n-placeholder="Texte de description par défaut lorsque le user n'a pas encore personnalisé ce champ
          (visible uniquement par la personne elle même)@@DescriptionProfileDefault"
          [class.focus]="forms.description.focus"
          [class.empty]="me.description === null || me.description.length === 0"
          (focus)="setFocusClass('description')" (blur)="setBlurClass('description')"
          (input)="descriptionListener($event)"
          ></textarea>
      </div>
    </label>
    <label>
      <div class="range-holder">
        <div class="label">
          <h3 i18n="age@@Age">Votre âge</h3>
          <div class="age-value">{{ ageSlider.value }}</div>
        </div>
        <mat-slider [(ngModel)]="me.age" step="1" min="18" max="100" #ageSlider name="age" (input)="ageListener($event)"></mat-slider>
      </div>
    </label>
    <app-city-input [val]="me.city" i18n-placeholder="Subsitue ville|Placeholder du champs ville@@CityPlaceholder"
      (emitCity)="setCity($event);"></app-city-input>
  </div>
</form>


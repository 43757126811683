import { Component, OnInit, Input, HostBinding, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AvatarService } from './avatar.service';
import { environment } from '../../../../environments/environment';
import { trigger, transition, query, style, stagger, animate } from '@angular/animations';
import { AppService } from 'src/app/app.service';
import { User } from 'src/app/shared/interfaces/user';
import { Avatar } from 'src/app/shared/interfaces/avatar';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-account-profil-avatars',
  templateUrl: './account-profil-avatars.component.html',
  styleUrls: ['./account-profil-avatars.component.scss'],
  animations: [
    trigger('popAnimation', [
      transition(':enter', [
        query(
          '.pop',
          [
            style({
              opacity: 0,
              transform: 'scale(1.1)'
            }),
            stagger(50, [
              animate(
                '0.5s',
                style({
                  opacity: 1,
                  transform: 'scale(1)'
                })
              )
            ])
          ],
          { optional: true }
        )
      ])
    ])
  ]
})
export class AccountProfilAvatarsComponent implements OnInit, OnDestroy {
  @HostBinding('@popAnimation')

  /** Image error dialog template */
  @Input()
  me: User;

  avatarsPlaceholder: Avatar[];

  loadingAvatar = false;

  avatarPlaceholder = [];
  private onAvatarDeleteSubscription: Subscription;
  private onNewAvatarSubscription: Subscription;

  constructor(private avatars: AvatarService, private dialog: AppService) {}

  ngOnInit(): void {
    this.setAvatarsTiled();
    this.onAvatarDeleteSubscription = this.avatars.onAvatarDelete.subscribe(avatar =>
      this.deleteAvatar(this.me, avatar)
    );
    this.onNewAvatarSubscription = this.avatars.getNewAvatar().subscribe(avatar => {
      this.me.avatars.splice(1, 0, avatar);
      this.me.avatars = this.me.avatars.filter(a => a.id !== null);
      if (this.me.avatars.length <= 1) {
        this.me.avatar = avatar;
        this.defineAsMainAvatar(avatar);
      }
      const use = this.me;
      use.avatar = avatar;
      use.avatars.splice(use.avatars.indexOf(avatar), 1);
      use.avatars.splice(0, 0, avatar);
      this.setAvatarsTiled();
    });
  }
  ngOnDestroy() {
    this.onAvatarDeleteSubscription.unsubscribe();
    this.onNewAvatarSubscription.unsubscribe();
  }
  setAvatarsTiled() {
    const addButton = !this.me.avatars[0].id ? 0 : this.me.avatars.length;
    const hasAddButton = this.me.avatars.length < 9;
    this.avatarsPlaceholder = [];
    for (let i = 0; i < 9; i++) {
      if (!this.me.avatars[i]) {
        this.avatarsPlaceholder.push({
          id: null,
          large: null,
          thumb: null
        });
      } else {
        if (!this.me.avatars[i].id) {
          this.avatarsPlaceholder.push({
            id: null,
            large: null,
            thumb: null
          });
        } else {
          this.avatarsPlaceholder.push(this.me.avatars[i]);
        }
      }
    }
    if (hasAddButton) {
      this.avatarsPlaceholder[addButton] = {
        id: -1,
        large: null,
        thumb: 'assets/svg/profil.svg'
      };
    }
  }
  defineAsMainAvatar(avatar: Avatar): void {
    this.avatars.defineMainAvatar(avatar).subscribe(
      () => {
        const use = this.me;
        use.avatar = avatar;
        use.avatars.splice(use.avatars.indexOf(avatar), 1);
        use.avatars.splice(0, 0, avatar);
      },
      error => console.error(error)
    );
  }
  deleteAvatar(user: User, avatarToDelete: Avatar): void {
    if (!avatarToDelete) {
      return;
    }
    user.avatars = user.avatars.filter(avatar => avatar.id !== avatarToDelete.id);
    if (user.avatars.length <= 0) {
      const defaultAvatarType = user.gender === 1 ? 'Male' : 'Female';
      const defaultAvatarImage = `${environment.api.replace(
        '/api',
        ''
      )}/images/avatar/${defaultAvatarType}.png`;
      const defaultAvatar: Avatar = {
        id: null,
        large: defaultAvatarImage,
        thumb: defaultAvatarImage
      };
      user.avatars.push(defaultAvatar);
    }
    user.avatar = user.avatars[0];
    this.avatars.onMainAvatarChange.next(user.avatar);
    this.setAvatarsTiled();
    // });
  }
  onPhotoInputChange($event: FileEvent) {
    this.avatars.setAddAvatarSubject($event);
  }
  openDeleteAvatarDialog(avatar: Avatar): void {
    this.dialog.throwDialog({
      display: true,
      type: 'avatar',
      avatar
    });
  }
}

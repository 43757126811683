<mat-vertical-stepper #stepper>
  <mat-step editable="false">
    <form>
      <ng-template matStepLabel i18n="popup premiere connexion titre pseudo@@PersonalizationPopPhoto">Ajouter une photo de profil</ng-template>
      <div class="avatar-background">
        <button class="button button-icon wide darken" (click)="photoFileInput.click()">
          <input #photoFileInput type="file" accept="image/*" (change)="onPhotoInputChange($event)"
            angulartics2On="click" angularticsAction="clic_add_photo_popup_pseudo_photo" angularticsCategory="cat_popup_photo_pseudo">
          <mat-icon svgIcon="photo-add"></mat-icon>
        </button>
      </div>
      <div class="step-change-holder">
        <button matStepperNext>
          <ng-container i18n="ingorer@@Ignor">Ignorer</ng-container><mat-icon svgIcon="close"></mat-icon>
        </button>
      </div>
    </form>
  </mat-step>
  <mat-step editable="false">
    <form #form="ngForm" (ngSubmit)="saveProfil()">
      <ng-template matStepLabel i18n="popup premiere connexion titre pseudo@@PersonalizationPopPseudo">Personnalisez votre pseudo</ng-template>
      <mat-form-field>
        <input matInput placeholder="Votre pseudo" [(ngModel)]="me.nickname" name="nickname"
        i18n-placeholder="Placeholder du champs identifiant du formulaire de connexion@@PseudoChangePlaceholder"
        (input)="dataListener($event)">
      </mat-form-field>
      <button type="submit" i18n="enregistrer@@Save" class="button button-finger accent"
        *ngIf="enableSave else final">Enregistrer</button>
      </form>
      <ng-template #final>
        <div class="step-change-holder" (click)="showProfil()">
            <ng-container i18n="aller au profil@@GoToProfil">Accèder à mon profil</ng-container><mat-icon svgIcon="man"></mat-icon>
        </div>
        <div class="step-change-holder" (click)="closeWizzard()">
            <ng-container i18n="ingorer@@Ignor">Ignorer</ng-container><mat-icon svgIcon="close"></mat-icon>
        </div>
      </ng-template>
  </mat-step>
  <ng-template matStepperIcon="done">
    <mat-icon svgIcon="ok"></mat-icon>
  </ng-template>
</mat-vertical-stepper>
<app-account-profil-avatar-crop [imageChangedEvent]="newImage" *ngIf="crop" (closing)="closeCrop($event)" (crop)="cropImage($event)"></app-account-profil-avatar-crop>

import { Component, HostBinding, Output, EventEmitter, Input } from '@angular/core';
import { trigger, style, animate, transition, query, stagger } from '@angular/animations';
import { AppInitializerService } from 'src/app/app-initializer.service';
import { environment } from 'src/environments/environment';
/**
 * Chat footer component
 *
 * Show links set in environment
 */
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  animations: [
    trigger('footerAnimation', [
      transition(':enter', [
        query(
          '.legal',
          [
            style({
              opacity: 0,
              transform: 'scale(1.3)',
            }),
          ],
          { optional: true }
        ),
        query('.legal-background', [
          style({
            opacity: 0,
            transform: 'scale(0)',
          }),
          animate(
            '.25s ease-out',
            style({
              opacity: 1,
              transform: 'scale(1)',
            })
          ),
        ]),
        query(
          '.legal',
          [
            style({
              opacity: 0,
              transform: 'scale(1.3)',
            }),
            stagger(50, [
              animate(
                '0.5s',
                style({
                  opacity: 1,
                  transform: 'scale(1)',
                })
              ),
            ]),
          ],
          { optional: true }
        ),
      ]),
      transition(':leave', [
        query(
          '.legal',
          [
            stagger(50, [
              animate(
                '0.5s',
                style({
                  opacity: 0,
                })
              ),
            ]),
          ],
          { optional: true }
        ),
        query('.legal-background', [
          animate(
            '.25s ease-out',
            style({
              opacity: 0,
              transform: 'scale(0)',
            })
          ),
        ]),
        query('.legal-overlay', [
          animate(
            '.25s ease-out',
            style({
              opacity: 0,
            })
          ),
        ]),
      ]),
    ]),
  ],
})
export class FooterComponent {
  @HostBinding('@footerAnimation') animation = true;
  @Input() displayBanner: boolean;
  @Output() hideLegal = new EventEmitter<boolean>();
  links;
  env = environment;

  legalDisplay = false;
  cookieLink = this.api.params.cookieLink;

  readonly text = this.api.params.footerText;

  constructor(private api: AppInitializerService) {
    console.log('construct footer banner');
    this.links = this.api.params.footerLinks;
  }
  closing() {
    if (this.displayBanner) {
      this.setCookieToken();
    }
    this.hideLegal.emit(true);
  }
  setCookieToken() {
    const timer = Date.now();
    localStorage.setItem('cookieAgrement', timer.toString());
  }
}

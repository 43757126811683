<div class="avatar-holder">
  <div class="avatar pop" [class.chosen]="avatar.thumb === me.avatar.thumb" [class.empty]="!avatar.id"
    [class.add]="0 > avatar.id" *ngFor="let avatar of avatarsPlaceholder">
    <div class="avatar-background">
      <ng-container *ngIf="avatar.id">
        <ng-container *ngIf="0 > avatar.id else pics">
          <button class="button button-icon wide darken" (click)="photoFileInput.click()">
            <input #photoFileInput type="file" accept="image/*" (change)="onPhotoInputChange($event)"
              angulartics2On="click" angularticsAction="clic_add_photo" angularticsCategory="cat_my_profile">
            <mat-icon svgIcon="photo-add"></mat-icon>
          </button>
        </ng-container>
      </ng-container>
      <ng-template #pics>
        <img [src]="avatar.thumb" [alt]="avatar.id" (click)="defineAsMainAvatar(avatar)">
        <button class="remove button button-round lighten wrapper-close" (click)="openDeleteAvatarDialog(avatar)">
          <mat-icon svgIcon="close"></mat-icon>
        </button>
      </ng-template>
    </div>
  </div>
</div>

<div class="button-holder" [class.hide]="hideElement">
  <button
    class="button button-icon darken"
    [class.accent]="slideActive === 'auth'"
    (click)="changeSlide('auth')"
    angulartics2On="click"
    angularticsAction="clic_auth_section "
    angularticsCategory="cat_my_profile"
  >
    <mat-icon svgIcon="key"></mat-icon>
    <p i18n="mes accès@@MyAccess">Mes accès</p>
    <div class="sticker absolute accent icon" *ngIf="accessError">
      <mat-icon svgIcon="warning"></mat-icon>
    </div>
  </button>
  <button
    class="button button-icon darken"
    [class.accent]="slideActive === 'profil'"
    (click)="changeSlide('profil')"
    angulartics2On="click"
    angularticsAction="clic_my_profile_section "
    angularticsCategory="cat_my_profile"
  >
    <mat-icon svgIcon="information"></mat-icon>
    <p i18n="mon profil@@MyProfile">Mes infos</p>
    <div class="sticker absolute accent icon" *ngIf="informationError">
      <mat-icon svgIcon="warning"></mat-icon>
    </div>
  </button>
  <button
    class="button button-icon darken"
    [class.accent]="slideActive === 'avatars'"
    (click)="changeSlide('avatars')"
    angulartics2On="click"
    angularticsAction="clic_photo_section "
    angularticsCategory="cat_my_profile"
  >
    <mat-icon svgIcon="photo"></mat-icon>
    <p i18n="mes photos@@MyPhotos">Mes photos</p>
    <div class="sticker absolute primary" [class.accent]="slideActive === 'avatars'">
      {{ me.avatars[0].id === null ? 0 : me.avatars.length }}/9
    </div>
  </button>
  <button
    class="button button-icon darken"
    [class.accent]="slideActive === 'hobbies'"
    (click)="changeSlide('hobbies')"
    angulartics2On="click"
    angularticsAction="clic_hobbies_section "
    angularticsCategory="cat_my_profile"
  >
    <mat-icon svgIcon="like"></mat-icon>
    <p i18n="mes loisirs@@MyHobbies">Mes loisirs</p>
    <div class="sticker absolute primary" [class.accent]="slideActive === 'hobbies'">
      {{ hobbiesSelectedValue }}/9
    </div>
  </button>
</div>
<div class="slide-holder" [class.show]="hideElement" [class.hobbies]="slideActive === 'hobbies'">
  <app-account-auth
    [me]="meInput"
    *ngIf="slideActive === 'auth'"
    (saveChanges)="showHideSaveButton($event)"
  >
  </app-account-auth>
  <app-account-profil-infos
    [me]="meInput"
    *ngIf="slideActive === 'profil'"
    (saveChanges)="showHideSaveButton($event)"
  >
  </app-account-profil-infos>
  <app-account-profil-avatars
    [me]="me"
    *ngIf="slideActive === 'avatars'"
  ></app-account-profil-avatars>
  <app-account-profil-hobbies
    [me]="meInput"
    *ngIf="slideActive === 'hobbies'"
    (saveChanges)="showHideSaveButton($event)"
    (hobbiesSelected)="setHobbiesSelectedValue($event)"
  ></app-account-profil-hobbies>
  <div class="account-sub-footer">
    <div class="tiny-loader" *ngIf="submitted && !datasSaved">
      <div class="tiny-loader-ripple">
        <div class="ripple ripple-left"></div>
        <div class="ripple ripple-right"></div>
      </div>
      <mat-icon [svgIcon]="me.gender === 1 ? 'man' : 'woman'"></mat-icon>
    </div>
    <button class="ok" *ngIf="datasSaved">
      <mat-icon svgIcon="ok"></mat-icon>
    </button>
    <button
      type="submit"
      i18n="enregistrer@@Save"
      class="button button-finger accent"
      angulartics2On="click"
      angularticsAction="clic_save_modification"
      angularticsCategory="cat_my_profile"
      *ngIf="!submitted && !datasSaved && enable"
      (click)="saveProfil()"
    >
      Enregistrer
    </button>

    <button
      type="submit"
      class="button button-finger disabled"
      *ngIf="!submitted && !datasSaved && !enable && warning"
    >
      <mat-icon svgIcon="warning"> </mat-icon>
      <span i18n="enregistrer@@Save"> Enregistrer </span>
    </button>
  </div>
</div>
<div class="account-profil-actions" [class.hide]="hideElement">
  <ng-container *ngIf="linkedSite">
    <a class="button button-icon darken" *ngFor="let site of linkedSite" [href]="site.url">
      <ng-container *ngIf="site.extCode === 'NL'">
        <img [src]="'assets/flags/lang-' + site.extCode + '.png'" />
        <span>Nederlandse versie</span>
      </ng-container>
      <ng-container *ngIf="site.extCode === 'EN IQ'">
        <span>English version</span>
      </ng-container>
      <ng-container *ngIf="site.extCode === 'IQ'">
        <span>النسخة العربية</span>
      </ng-container>
    </a>
  </ng-container>
  <button
    class="button button-icon darken"
    (click)="deleteAccount()"
    angulartics2On="click"
    angularticsAction="delete_my_account "
    angularticsCategory="cat_my_profile"
  >
    <mat-icon svgIcon="close"></mat-icon>
    <span i18n="Supprimer@@RemoveAccount">Supprimer mon compte</span>
  </button>
  <button class="button button-icon darken" (click)="logout()">
    <mat-icon svgIcon="disconnect"></mat-icon>
    <span i18n="déconnexion@@accounterDisconnect">Déconnexion</span>
  </button>
</div>

<app-account-profil-avatar-crop
  [imageChangedEvent]="newImage"
  *ngIf="crop"
  (closing)="closeCrop($event)"
  (crop)="cropImage($event)"
></app-account-profil-avatar-crop>

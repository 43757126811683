import { Injectable } from '@angular/core';
import { CustomDimensions } from '../shared/interfaces/custom-dimensions';

/** GTM Custom dimensions service */
@Injectable({
  providedIn: 'root'
})
export class CustomDimensionsService {
  private readonly key = 'customDimensions';

  get(): CustomDimensions {
    return JSON.parse(localStorage.getItem(this.key));
  }

  set(customDimensions: CustomDimensions): void {
    localStorage.setItem(this.key, JSON.stringify(customDimensions));
  }
}

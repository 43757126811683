<div class="account">
  <div class="account-background wizzard" [class.profil]="displayProfil" [class.wizzard]="displayWizzard"></div>
  <h2 i18n="Popup incitant l'utilisateur à uploader une photo et modifier son pseudo@@PersonalizationPopUpTitle"
    *ngIf="displayWizzard">
    Augmentez de <b>65 %</b> vos chances d'être contacté
  </h2>
  <nav fxLayout="row" *ngIf="me" [class.profil]="displayProfil || displayWizzard">
    <a (click)="editProfil();">
      <ng-container *ngIf="!displayProfil else close">
        <div [ngClass]="['avatar', isMan ? 'man' : 'woman']" *ngIf="me.avatar.id === null else imgloaded">
          <mat-icon [svgIcon]="isMan ? 'man' : 'woman'"></mat-icon>
        </div>
      </ng-container>
    </a>
    <button class="button button-round lighten fixe" *ngIf="hasInfoPage && !displayProfil && !displayWizzard"
      angulartics2On="click" angularticsAction="clic_news_section " angularticsCategory="cat_news"
      [routerLink]="['chat/news']">
      <mat-icon svgIcon="lamp"></mat-icon>
    </button>
    <button class="button button-round lighten fixe" *ngIf="hasQuiz && !displayProfil && !displayWizzard"
      angulartics2On="click" angularticsAction="clic_news_section " angularticsCategory="cat_news"
      [routerLink]="['chat/quiz']">
      <mat-icon svgIcon="quiz"></mat-icon>
    </button>
    <div class="nav-nickname" [class.profil]="displayProfil">
      {{me.nickname}}
    </div>
  </nav>
  <app-account-profil *ngIf="displayProfil" [me]="me" (savingRequest)="savingRequest($event)"></app-account-profil>
  <app-account-profil-wizzard *ngIf="displayWizzard" (closing)="closeWizzard($event)" (showing)="showProfil($event)"
    [me]="me"></app-account-profil-wizzard>
</div>

<ng-template #close>
  <mat-icon svgIcon="close" *ngIf="displayProfil"></mat-icon>
</ng-template>
<ng-template #imgloaded>
  <img class="avatar" [src]="me.avatar.thumb" [alt]="me.nickname">
</ng-template>

import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router, NavigationExtras } from '@angular/router';
import { TokenService } from './account/token.service';
import { AppService } from './app.service';
import { UserService } from './account/user.service';

@Injectable({
  providedIn: 'root',
})
export class AppInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private token: TokenService,
    private dialog: AppService,
    private user: UserService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (event) => {
          if (event instanceof HttpResponse) {
            if (event.body.code === 500) {
              const err = event.body.error_code;
              // const err = 716;
              let error = '';
              switch (err) {
                case 606:
                  return false;
                case 2:
                  error = 'FORBIDDEN';
                  break;
                case 105:
                  error = 'ConnexionIDAuto';
                  break;
                case 108:
                  error = 'BadCredentials';
                  break;
                case 106:
                case 111:
                case 114:
                  error = 'UnknownErrorSignIn';
                  break;
                case 107:
                case 118:
                case 160:
                  error = 'ConnexionPasswordEmpty';
                  break;
                case 109:
                case 135:
                  error = 'ConnexionLoginEmpty';
                  break;
                case 110:
                case 115:
                  error = 'AccountBanned';
                  break;
                case 112:
                case 116:
                  error = 'AccountDeleted';
                  break;
                case 117:
                  error = 'BadLogin';
                  break;
                case 139:
                  error = 'ValidationInvalidPhoneNumber';
                  break;
                case 140:
                  error = 'ValidationUniquePhoneNumber';
                  break;
                case 141:
                case 165:
                  error = 'NotEmailOrPhoneError';
                  break;
                case 142:
                case 153:
                case 154:
                case 155:
                case 156:
                  error = 'PseudoIsNumeric';
                  break;
                case 143:
                case 157:
                case 158:
                case 159:
                  error = 'TooShortPassword';
                  break;
                case 150:
                  error = 'uniqueLogin';
                  break;
                case 162:
                  console.log('case 162');
                  error = 'ValidationRequiredEmail';
                  break;
                case 151:
                case 152:
                  error = 'shortLogin';
                  break;
                case 163:
                  error = 'MyProfileEmailUnique';
                  break;
                case 164:
                case 305:
                  error = 'badEmailFormat';
                  break;
                case 172:
                  // error = 'badEmailFormat';
                  console.log('on passe ici ? ');
                  const params: NavigationExtras = {
                    queryParams: {
                      authToken: event.body.message,
                    },
                  };
                  return this.router.navigate(['/completeProfile'], params);
                  break;
                case 300:
                case 303:
                  error = 'PasswordRecuperationWrongEmailOrPhone';
                  break;
                case 301:
                  error = 'PasswordRecuperationWrongEmail';
                  break;
                case 302:
                  error = 'NotEmailError';
                  break;
                case 304:
                  error = 'ValidationRequiredEmail';
                  break;
                case 702:
                case 719:
                  error = 'UnavailabilityService';
                  break;
                case 716:
                case 718:
                  error = 'NotEnoughCredit';
                  break;
                case 717:
                  error = 'NonSubscriber';
                  break;
                case 721:
                  error = 'NotEnoughCredit';
                  break;
                case 5:
                  const hasToken = !!this.token.get();
                  error = 'USERAUTH_INVALID';
                  this.router.navigate(['/connection']);
                  if (!hasToken) {
                    return true;
                  }
                  this.token.remove();
                  this.user.unlogged();
                  break;
                default:
                  error = 'DEFAULT';
                  break;
              }
              this.dialog.throwError({
                message: event.body.message,
                code: event.body.error_code,
                error,
              });
              // throw new Error(event.body.error_code);
            }
          }
        },
        (error) => {
          if (event instanceof HttpResponse) {
          }
        }
      )
    );
  }
}

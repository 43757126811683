<nav fxLayout="row">
  <a routerLink="account">
    <div [ngClass]="['avatar', isMan ? 'man' : 'woman']" *ngIf="me.avatar.id === null else imgloaded">
      <mat-icon svgIcon="man" *ngIf="isMan"></mat-icon>
      <mat-icon svgIcon="woman" *ngIf="!isMan"></mat-icon>
    </div>
  </a>
  <a *ngIf="hasInfoPage" [routerLink]="['info']" routerLinkActive="active" class="infos">
    <button class="button button-round lighten fixe" [class.hidden]="flapState === 'opened'" angulartics2On="click"
      angularticsAction="clic_news_section " angularticsCategory="cat_news">
      <mat-icon svgIcon="lamp"></mat-icon>
    </button>
  </a>
  <div class="nav-nickname">
    {{me.nickname}}
  </div>
</nav>

<ng-template #imgloaded>
  <img class="avatar" [src]="me.avatar.thumb" [alt]="me.nickname">
</ng-template>

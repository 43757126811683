<div class="footer">
  <div class="legal-overlay" (click)="closing()" [class.cookie]="displayBanner"></div>
  <div class="legal-background" [class.cookie]="displayBanner"></div>
  <div class="legal-content" [class.cookie]="displayBanner">
    <ng-container *ngIf="!displayBanner">
      <!-- <p fxFlex="100" *ngIf="text">{{text}}</p> -->
      <div *ngFor="let link of links" class="legal">
        <a [href]="link.link.href" [target]="link.link.target">{{ link.link.title }}</a>
      </div>
      <div *ngIf="env.didomi" class="legal">
        <a href="javascript:Didomi.preferences.show()">Consent choices</a>
      </div>
    </ng-container>
  </div>
</div>
<ng-template #banner>
  <div class="banner-RGPD">
    <p i18n="Text cookie@@WeezchatCookies">
      En poursuivant la navigation, vous acceptez le dépôt de cookies destinés à optimiser votre
      navigation, réaliser des statistiques et à vous proposer des offres adaptées à vos centres
      d’intérêts.
    </p>
    <a i18n="bouton en savoir plus@@WeezchatCookiesButton" [href]="cookieLink.href" target="_blank"
      >En savoir plus</a
    >
  </div>
</ng-template>

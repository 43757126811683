import { Component, OnInit, HostBinding } from '@angular/core';
import { ChatMessageService } from 'src/app/chat/chat-message.service';
import { ChatService } from 'src/app/chat/chat.service';
import { filter, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UserService } from 'src/app/account/user.service';
import { popAnimation } from 'src/app/shared/animations/pop-animation';
import { User } from 'src/app/shared/interfaces/user';
import { Conversation } from 'src/app/shared/interfaces/conversation';
import { Message } from 'src/app/shared/interfaces/message';
import { ConversationsService } from 'src/app/chat/conversations.service';
import { Subscription } from 'rxjs';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-conversations',
  templateUrl: './conversations.component.html',
  styleUrls: ['./conversations.component.scss'],
  animations: [popAnimation]
})
export class ConversationsComponent implements OnInit {
  @HostBinding('@popAnimation')

  me: User;

  hasConversation: boolean;

  chatConnected = false;
  newConversationSet = false;

  conversationsList: Conversation[] = [];
  private onConversationSubscription: Subscription;

  public resetUnreadCount(interlocutor: User): void {
    const interlocutorConversation = this.conversationsList.find(conversation => conversation.interlocutor.id === interlocutor.id);
    if (interlocutorConversation) {
      interlocutorConversation.unreadCount = 0;
    }
  }
  private updateConversationLastMessage(message: Message): void {
    let messageConversation = this.conversationsList.find(conversation => Number(conversation.id) === message.conversation);
    if (!messageConversation) {
      this.onConversationSubscription = this.conversations.getConversartion(message.conversation as number).subscribe( res => {
        this.onConversationSubscription.unsubscribe();
        this.conversationsList.unshift(res);
        messageConversation = res;
        if (this.me.id !== messageConversation.lastMessage.sender
          && this.router.url.indexOf(message.sender + '') < 0) {
          this.app.showNotif(messageConversation);
        }
      });
    } else {
      messageConversation.interlocutor.logged = true;
      messageConversation.lastMessage = message;
      if (this.me.id !== messageConversation.lastMessage.sender
        && this.router.url.indexOf(message.sender + '') < 0) {
          this.app.showNotif(messageConversation);
      }
    }
  }
  private removeConversationWith(interlocutor: number): void {
    const interlocutorConversation = this.conversationsList.find(conversation => conversation.interlocutor.id === interlocutor);
    if (interlocutorConversation) {
      this.messages.removeConversation(interlocutorConversation);
      this.conversationsList = this.conversationsList.filter(conversation => conversation !== interlocutorConversation);
    }
  }
  private removeConversation(conversationToRemove: Conversation): void {
    this.conversationsList = this.conversationsList.filter(conversation => conversation.id !== conversationToRemove.id);
  }
  private markConversation(interlocutor: number, logged: boolean): void {
    const interlocutorConversation = this.conversationsList.find(conversation => {
      return conversation.interlocutor.id === interlocutor;
    });
    if (interlocutorConversation) {
      interlocutorConversation.interlocutor.logged = logged;
    }
  }
  private incrementUnreadCount(interlocutor: number): void {
    const interlocutorConversation = this.conversationsList.find(conversation => conversation.interlocutor.id === interlocutor);
    if (interlocutorConversation) {
      interlocutorConversation.unreadCount += 1;
    }
  }
  constructor(
    private messages: ChatMessageService,
    private chat: ChatService,
    private user: UserService,
    private app: AppService,
    private conversations: ConversationsService,
    private router: Router
  ) { }

  ngOnInit() {
    this.user.logged().subscribe(login => {
      this.me = login;

      this.user.getConversations().subscribe(conversations => {
        this.conversationsList = conversations;
        this.hasConversation = conversations.length > 0;
        if (!this.chatConnected) {
          this.chatConnected = true;
          this.chat.connect(login);
          this.messages.onConversationDelete
            .subscribe(conversation => this.removeConversation(conversation));
          this.user.onUserBlock
            .subscribe(user => this.removeConversationWith(user.id));
          this.chat.onUserLogin
            .subscribe(user => { this.markConversation(Number(user.id), true); });
          this.chat.onUserLogout
            .subscribe(user => this.markConversation(user.id, false));
          this.chat.lastMessage
            .subscribe(message => {
              this.updateConversationLastMessage(message);
            });
          this.chat.onNotification
            .pipe(
              tap(message => this.updateConversationLastMessage(message)),
              filter(message => this.router.url.indexOf(message.sender + '') < 0)
            )
            .subscribe(message => {
              this.incrementUnreadCount(message.sender);
            });
        }
      });
    });
  }

}

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

if (environment.gtmCountry) {
  const gtmTag = document.createElement('script');
  gtmTag.innerText = `
    (function (w, d, s, l, i) {w[l] = w[l] || [];
    w[l].push({'gtm.start':new Date().getTime(), event: 'gtm.js'});
    var f = d.getElementsByTagName(s)[0],j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src ='https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);})(window, document, 'script', 'dataLayer', '${environment.gtmCountry}');
  `;
  document.head.appendChild(gtmTag);
}

if (environment.didomi) {
  console.log('didomi is setted', environment.didomi);
  const didomi = document.createElement('script');
  didomi.innerText = `
  window.gdprAppliesGlobally=false;(function(){(function(e,r)
    {var t=document.createElement("link");t.rel="preconnect";t.as="script";var n=document.createElement("link");
    n.rel="dns-prefetch";n.as="script";var i=document.createElement("link");i.rel="preload";i.as="script";
    var o=document.createElement("script");o.id="spcloader";o.type="text/javascript";o["async"]=true;o.charset="utf-8";
    var a="https://sdk.privacy-center.org/"+e+"/loader.js?target_type=notice&target="+r;
    if(window.didomiConfig&&window.didomiConfig.user){var c=window.didomiConfig.user;var s=c.country;var d=c.region;
      if(s){a=a+"&country="+s;if(d){a=a+"&region="+d}}}t.href="https://sdk.privacy-center.org/";
      n.href="https://sdk.privacy-center.org/";i.href=a;o.src=a;var p=document.getElementsByTagName("script")[0];
      p.parentNode.insertBefore(t,p);p.parentNode.insertBefore(n,p);p.parentNode.insertBefore(i,p);
      p.parentNode.insertBefore(o,p)})("997a47aa-b384-4386-ad9c-16910dff5088","${environment.didomi}")})();
  `;
  document.head.appendChild(didomi);
}
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

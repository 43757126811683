import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Conversation } from '../shared/interfaces/conversation';
import { TokenService } from '../account/token.service';
import { ApiResponse } from '../shared/interfaces/api-response';
import { User } from '../shared/interfaces/user';
import { Message } from '../shared/interfaces/message';
import { AppInitializerService } from '../app-initializer.service';

@Injectable({
  providedIn: 'root'
})
export class ChatMessageService {
  readonly onConversationDelete = new Subject<Conversation>();

  constructor(
    private http: HttpClient,
    private token: TokenService,
    private api: AppInitializerService
  ) {}

  conversationList(limit?: number, offset?: number): Observable<any> {
    let params = new HttpParams()
      .set('key', this.api.params.key.toString())
      .set('userauth', this.token.get());
    if (limit) {
      params = params.set('limit', limit.toString());
    }
    if (offset) {
      params = params.set('offset', offset.toString());
    }
    return this.http
      .get<ApiResponse<{ conversations: Conversation[] }>>(
        `${environment.api}/get_conversation_list`,
        { params }
      )
      .pipe(
        map(res => {
          if (res.error) {
            throw new Error(res.msg as string);
          }
          return res.data.conversations;
        })
      );
  }

  conversationMessages(interlocutor: User, limit = 10, offset = 0): Observable<Message[]> {
    const params = new HttpParams()
      .set('key', this.api.params.key.toString())
      .set('userauth', this.token.get())
      .set('interlocutorId', interlocutor.id.toString())
      .set('country', this.api.params.country)
      .set('offset', offset.toString())
      .set('limit', limit.toString());
    return this.http
      .get<ApiResponse<{ messages: Message[] }>>(`${environment.api}/get_conversation_messages`, {
        params
      })
      .pipe(
        map(res => {
          if (res.error) {
            throw new Error(res.msg as string);
          }
          return res.data.messages;
        })
      );
  }

  markMessageAsRead(message: Message): Observable<void> {
    const body = {
      key: this.api.params.key.toString(),
      userauth: this.token.get(),
      msgId: message.id.toString()
    };
    return this.http.put<ApiResponse<void>>(`${environment.api}/mark_msg_as_read`, body).pipe(
      map(res => {
        if (res.error) {
          throw new Error(res.msg as string);
        }
        return;
      })
    );
  }

  removeConversation(conversation: Conversation): Observable<Conversation> {
    const params = new HttpParams()
      .set('key', this.api.params.key.toString())
      .set('userauth', this.token.get())
      .set('conversationId', conversation.id.toString());
    return this.http
      .delete<ApiResponse<object>>(`${environment.api}/del_conversation`, { params })
      .pipe(
        map(res => {
          if (res.error) {
            throw new Error(res.msg as string);
          }
          this.onConversationDelete.next(conversation);
          return conversation;
        })
      );
  }

  sendMessage(receiver: User, message: string, photo: boolean): Observable<Message> {
    const now = new Date();
    const body = new FormData();
    body.append('key', this.api.params.key.toString());
    body.append('userauth', this.token.get());
    body.append('country', this.api.params.country);
    body.append('recipientId', receiver.id.toString());
    body.append('timeZone', now.getTimezoneOffset().toString());
    if (photo) {
      body.append('image', message);
    } else {
      body.append('msgBody', message);
    }
    return this.http
      .post<ApiResponse<{ message: Message }>>(`${environment.api}/send_msg_to_user`, body)
      .pipe(
        map(res => {
          console.log('chat message service send message return', res.data.message);
          return res.data.message;
        })
      );
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthResolverService } from './account/auth-resolver.service';
import { LoggedGuard } from './logged.guard';
import { LoginComponent } from './sign/login/login.component';
import { SubscriptionComponent } from './sign/subscription/subscription.component';
import { ForgetPasswordComponent } from './sign/forget-password/forget-password.component';

const routes: Routes = [
  { path: '', redirectTo: 'chat', pathMatch: 'full' },
  { path: 'connection', component: LoginComponent, canActivate: [LoggedGuard] },
  { path: 'completeProfile', component: SubscriptionComponent, canActivate: [LoggedGuard] },
  { path: 'forget-password', component: ForgetPasswordComponent },
  {
    path: 'chat',
    canActivateChild: [LoggedGuard],
    loadChildren: () => import('./chat/chat.module').then(m => m.ChatModule)
  },
  { path: '**', redirectTo: 'chat' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: [AuthResolverService]
})
export class AppRoutingModule {}

import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AvatarService } from 'src/app/account/account-profil/account-profil-avatars/avatar.service';
import { TokenService } from 'src/app/account/token.service';
import { UserService } from 'src/app/account/user.service';
import { AppService } from 'src/app/app.service';
import { ChatMessageService } from 'src/app/chat/chat-message.service';
import { ChatService } from 'src/app/chat/chat.service';
import { Avatar } from '../../interfaces/avatar';
import { Conversation } from '../../interfaces/conversation';
import { User } from '../../interfaces/user';
import { AppInitializerService } from 'src/app/app-initializer.service';

@Component({
  selector: 'app-block',
  templateUrl: './block.component.html',
  styleUrls: ['./block.component.scss'],
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({
          opacity: 0
        }),
        animate(
          '.2s ease-in',
          style({
            opacity: 1
          })
        )
      ]),
      transition(':leave', [
        animate(
          '.1s .2s ease-in',
          style({
            opacity: 0
          })
        )
      ])
    ]),
    trigger('ripple', [
      transition(':enter', [
        style({
          transform: 'scale(0)',
          opacity: 1
        }),
        animate(
          '.3s ease-in',
          style({
            transform: 'scale(1)',
            opacity: 0
          })
        )
      ]),
      transition(':leave', [
        style({
          transform: 'scale(0)',
          opacity: 1
        }),
        animate(
          '.3s ease-in',
          style({
            transform: 'scale(1)',
            opacity: 0
          })
        )
      ])
    ]),
    trigger('plop', [
      transition(':enter', [
        style({
          transform: 'scale(0)'
        }),
        animate(
          '.1s ease-in',
          style({
            transform: 'scale(1)'
          })
        )
      ]),
      transition(':leave', [
        animate(
          '.1s .2s ease-in',
          style({
            transform: 'scale(0)'
          })
        )
      ])
    ]),
    trigger('plop2', [
      transition(':enter', [
        style({
          transform: 'scale(0)'
        }),
        animate(
          '.1s .1s ease-in',
          style({
            transform: 'scale(1)'
          })
        )
      ]),
      transition(':leave', [
        animate(
          '.1s .1s ease-in',
          style({
            transform: 'scale(0)'
          })
        )
      ])
    ]),
    trigger('fade2', [
      transition(':enter', [
        style({
          opacity: 0
        }),
        animate(
          '.1s .3s ease-in',
          style({
            opacity: 1
          })
        )
      ]),
      transition(':leave', [
        animate(
          '.1s ease-in',
          style({
            opacity: 0
          })
        )
      ])
    ])
  ]
})
export class BlockComponent implements OnInit {
  @Input() popup: { type: string; conversation?: Conversation; user?: User; avatar?: Avatar };

  unsubscriptionLink = null;

  pwd: string;
  hiddenPassword = true;
  firstDisplay = true;
  confirmType = '';

  submiting = false;
  submitOk = false;
  deleteConfirm = false;
  reportReason: string;

  passwordEmpty = false;
  passwordWrong = false;
  datasSaved = false;
  // @Input() type: string;
  // @Input() data: {conversation?: Conversation, user?: User };

  constructor(
    private dialog: AppService,
    private message: ChatMessageService,
    private avatars: AvatarService,
    private users: UserService,
    private token: TokenService,
    private chat: ChatService,
    private router: Router,
    private api: AppInitializerService
  ) {
    if (this.api.params.country !== 'MX') {
      this.unsubscriptionLink = api.params.unsubscribeLink ? api.params.unsubscribeLink.href : null;
    } else {
      console.log(this.users.user);
    }
  }

  ngOnInit() {}
  removeConv() {
    this.message.removeConversation(this.popup.conversation).subscribe(() => {
      this.close();
    });
  }
  removeAvatar() {
    this.avatars.deleteAvatar(this.popup.avatar).subscribe(() => {
      this.dialog.throwDialog({ display: false, type: 'confirmed' });
    });
  }
  close() {
    this.dialog.throwDialog({ display: false, type: null });
  }
  onSubmit() {
    this.users.deleteAccount(this.popup.user.id.toString(), this.pwd).subscribe(res => {
      if (res.code === 200) {
        this.deleteConfirm = true;
        this.chat.disconnect();
        this.token.remove();
        this.router.navigate(['/connection']);
      } else {
        this.passwordWrong = true;
      }
    });
  }
  blockAndReport() {
    this.users.blockAndReport(this.popup.user).subscribe(res => {
      // user.under_your_blacklist = true;
    });
  }
  block(): void {
    this.submiting = true;
    this.users.block(this.popup.user).subscribe(res => {
      this.submitOk = true;
      this.submiting = false;
      if (res.code === 200) {
        setTimeout(() => this.close(), 3000);
        this.popup.user.under_your_blacklist = true;
        this.users.onUserBlock.next(this.popup.user);
      } else {
        this.close();
      }
    });
  }
  showConfirm(type: string) {
    this.firstDisplay = false;
    this.confirmType = type;
  }
  cancelConfirm() {
    this.firstDisplay = true;
  }
}

import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { CitySearchService } from './city-search.service';
import { City } from '../interfaces/city';
import { debounceTime } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { Subscription } from 'rxjs';
import { AppInitializerService } from 'src/app/app-initializer.service';

@Component({
  selector: 'app-city-input',
  templateUrl: './city-input.component.html',
  styleUrls: ['./city-input.component.scss'],
})
export class CityInputComponent implements OnInit {
  @Input() val: City;
  @Input() call: boolean;
  @Output() emitCity = new EventEmitter<City | string>();

  cities: City[];
  citiesAutoComplete: City[];
  inputValue: string;
  hasAutoComplete;
  focus = false;
  valueSubscription: Subscription;

  set newVal(value: string) {
    this.inputValue = value;
    if (!this.hasAutoComplete) {
      this.searchCities
        .byName(10, value)
        .pipe(debounceTime(500))
        .subscribe((res) => {
          this.cities = res;
        });
    } else {
      this.cities = this.citiesAutoComplete.filter(
        (city) => value.toLocaleUpperCase() === city.name.slice(0, value.length).toLocaleUpperCase()
      );
    }
  }

  get newVal(): string {
    return this.inputValue;
  }

  constructor(
    private searchCities: CitySearchService,
    private dialog: AppService,
    private api: AppInitializerService
  ) {
    this.hasAutoComplete = api.params.showAllCities;
  }

  ngOnInit() {
    this.inputValue = this.val.name;
    if (this.hasAutoComplete) {
      this.searchCities.byName(250).subscribe((cities) => {
        this.citiesAutoComplete = cities;
        this.cities = cities;
      });
    } else {
      this.searchCities.byName(10, this.inputValue).subscribe((cities) => {
        this.cities = cities;
      });
    }
  }
  resetInput() {
    this.newVal = null;
    this.emitCity.emit('');
  }
  switchFocus(value) {
    if (window.innerWidth <= 960) {
      // this.cdr.detectChanges();
      this.dialog.showInput(value, true);
      this.valueSubscription = this.dialog.state.subscribe((res) => {
        this.setCity(res.value);
        this.valueSubscription.unsubscribe();
      });
    } else {
      this.focus = value === null || !this.focus;
    }
  }
  setCity(city: City) {
    this.newVal = city.name;
    this.val = city;
    this.emitCity.emit(city);
  }
}

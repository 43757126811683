<form #passwordRecoveryForm="ngForm" (ngSubmit)="onSubmit()">
  <div class="container" *ngIf="!success; else successTemplate">
    <h3 i18n="
        Titre retrouver mot de passe|Titre de la section mot de passe oublié@@ForgottenPasswordTitle
      ">
      Recevoir votre mot de passe
    </h3>
    <p i18n="
        Insctruction retrouver mot de passe|Instruction de la section mot de passe
        oublié@@ForgottentPasswordInstruction
      ">
      Veuillez indiquer l'adresse de votre compte Weezchat pour recevoir votre mot de passe
    </p>
    <label class="login-input">
      <mat-icon color="accent" svgIcon="man"></mat-icon>
      <ng-container [ngSwitch]="SignUpType">

        <mat-form-field *ngSwitchCase="SignUpType === 'email'">
          <input matInput type="email" name="email" i18n-placeholder="Placeholder du champs e-mail@@EmailPlaceHolder"
            placeholder="E-mail" [(ngModel)]="email" #emailControl="ngModel" required email />
          <mat-error *ngIf="emailControl.invalid" i18n="erreur de mauvais format d'email@@NotEmailError">Vous devez
            renseigner un email</mat-error>
        </mat-form-field>
        <mat-form-field *ngSwitchDefault>
          <input matInput type="text" name="email" i18n-placeholder="
              Wording du champ de saisie dans la procédure du mot de passe
              oublié@@EmailPhoneNumberField
            " placeholder="E-mail ou téléphone" [(ngModel)]="email" #phoneAndEmailControl="ngModel" required phone />
          <mat-error *ngIf="phoneAndEmailControl.invalid"
            i18n="erreur de mauvais format d'email ou de téléphone@@NotEmailOrPhoneError">Vous devez renseigner un email
            ou un numéro</mat-error>
        </mat-form-field>
      </ng-container>
    </label>
  </div>
  <ng-container *ngIf="!success">
    <button class="button button-finger accent" [class.disabled]="passwordRecoveryForm.invalid" type="submit"
      i18n="Retrouver mot de passe|Bouton de recupération du mot de passe@@RetrievePassword"
      *ngIf="!submitted; else loading">
      Envoyer
    </button>
  </ng-container>

  <a routerLink="/connection" i18n="Bouton de connexion@@SignIn">Connexion</a>
  <ng-template #loading>
    <div class="tiny-loader" *ngIf="submitted && !success">
      <div class="tiny-loader-ripple">
        <div class="ripple ripple-left"></div>
        <div class="ripple ripple-right"></div>
      </div>
      <mat-icon svgIcon="key"></mat-icon>
    </div>
  </ng-template>
  <ng-template #successTemplate>
    <p i18n="email recupération mot de passe envoyé@@RecoveryPasswordEmailSent">
      Récupération de votre mot de passe. Votre mot de passe va vous être transmis à l'adresse email
      ou au numéro de téléphone que vous avez indiqués.
    </p>
  </ng-template>
</form>
